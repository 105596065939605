import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, Row, Table } from 'reactstrap';
import { JhiItemCount, JhiPagination, Translate, getPaginationState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, searchEntities } from './appointments.reducer';

export const Appointments = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const appointmentsList = useAppSelector(state => state.appointments.entities);
  const loading = useAppSelector(state => state.appointments.loading);
  const totalItems = useAppSelector(state => state.appointments.totalItems);

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="appointments-heading" data-cy="AppointmentsHeading">
        <Translate contentKey="tinyMedicRestApp.appointments.home.title">Appointments</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="tinyMedicRestApp.appointments.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/appointments/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="tinyMedicRestApp.appointments.home.createLabel">Create new Appointments</Translate>
          </Link>
        </div>
      </h2>
      <Row>
        <Col sm="12">
          <Form onSubmit={startSearching}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  defaultValue={search}
                  onChange={handleSearch}
                  placeholder={translate('tinyMedicRestApp.appointments.home.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <div className="table-responsive">
        {appointmentsList && appointmentsList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.id">Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('appointmentDate')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.appointmentDate">Appointment Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('appointmentDate')} />
                </th>
                <th className="hand" onClick={sort('phoneNo')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.phoneNo">Phone No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('phoneNo')} />
                </th>
                <th className="hand" onClick={sort('email')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.email">Email</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('email')} />
                </th>
                <th className="hand" onClick={sort('appointmentTypeCode')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.appointmentTypeCode">Appointment Type Code</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('appointmentTypeCode')} />
                </th>
                <th className="hand" onClick={sort('apptStartTime')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.apptStartTime">Appt Start Time</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('apptStartTime')} />
                </th>
                <th className="hand" onClick={sort('apptEndTime')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.apptEndTime">Appt End Time</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('apptEndTime')} />
                </th>
                <th className="hand" onClick={sort('appointmentAgenda')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.appointmentAgenda">Appointment Agenda</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('appointmentAgenda')} />
                </th>
                <th className="hand" onClick={sort('consultationCharge')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.consultationCharge">Consultation Charge</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('consultationCharge')} />
                </th>
                <th className="hand" onClick={sort('primaryAppointmentNumber')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.primaryAppointmentNumber">Primary Appointment Number</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('primaryAppointmentNumber')} />
                </th>
                <th className="hand" onClick={sort('capturedDtm')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.capturedDtm">Captured Dtm</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('capturedDtm')} />
                </th>
                <th className="hand" onClick={sort('appointmentRemarks')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.appointmentRemarks">Appointment Remarks</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('appointmentRemarks')} />
                </th>
                <th className="hand" onClick={sort('nextVisitDt')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.nextVisitDt">Next Visit Dt</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('nextVisitDt')} />
                </th>
                <th className="hand" onClick={sort('createDtm')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.createDtm">Create Dtm</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createDtm')} />
                </th>
                <th className="hand" onClick={sort('createdBy')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.createdBy">Created By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdBy')} />
                </th>
                <th className="hand" onClick={sort('modifiedBy')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.modifiedBy">Modified By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedBy')} />
                </th>
                <th className="hand" onClick={sort('timestampentry')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.timestampentry">Timestampentry</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('timestampentry')} />
                </th>
                <th className="hand" onClick={sort('microSec')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.microSec">Micro Sec</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('microSec')} />
                </th>
                <th className="hand" onClick={sort('lastupdated')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.lastupdated">Lastupdated</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastupdated')} />
                </th>
                <th className="hand" onClick={sort('active')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.active">Active</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('active')} />
                </th>
                <th className="hand" onClick={sort('cursor')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.cursor">Cursor</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('cursor')} />
                </th>
                <th className="hand" onClick={sort('createdByType')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.createdByType">Created By Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdByType')} />
                </th>
                <th className="hand" onClick={sort('discount')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.discount">Discount</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('discount')} />
                </th>
                <th className="hand" onClick={sort('discountType')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.discountType">Discount Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('discountType')} />
                </th>
                <th className="hand" onClick={sort('patientType')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.patientType">Patient Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('patientType')} />
                </th>
                <th className="hand" onClick={sort('exposed')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.exposed">Exposed</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('exposed')} />
                </th>
                <th className="hand" onClick={sort('referedByName')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.referedByName">Refered By Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('referedByName')} />
                </th>
                <th className="hand" onClick={sort('referedByTown')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.referedByTown">Refered By Town</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('referedByTown')} />
                </th>
                <th className="hand" onClick={sort('paymentMethod')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.paymentMethod">Payment Method</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('paymentMethod')} />
                </th>
                <th className="hand" onClick={sort('paymentStatus')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.paymentStatus">Payment Status</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('paymentStatus')} />
                </th>
                <th className="hand" onClick={sort('transactionRef')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.transactionRef">Transaction Ref</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('transactionRef')} />
                </th>
                <th className="hand" onClick={sort('emergency')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.emergency">Emergency</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('emergency')} />
                </th>
                <th className="hand" onClick={sort('highRisk')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.highRisk">High Risk</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('highRisk')} />
                </th>
                <th className="hand" onClick={sort('isMedicoLegal')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.isMedicoLegal">Is Medico Legal</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isMedicoLegal')} />
                </th>
                <th className="hand" onClick={sort('consultType')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.consultType">Consult Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('consultType')} />
                </th>
                <th className="hand" onClick={sort('checkinTime')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.checkinTime">Checkin Time</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('checkinTime')} />
                </th>
                <th className="hand" onClick={sort('printed')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.printed">Printed</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('printed')} />
                </th>
                <th className="hand" onClick={sort('printCount')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.printCount">Print Count</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('printCount')} />
                </th>
                <th className="hand" onClick={sort('appliedCategoryCode')}>
                  <Translate contentKey="tinyMedicRestApp.appointments.appliedCategoryCode">Applied Category Code</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('appliedCategoryCode')} />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.scheme">Scheme</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.admission">Admission</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.bookingTypeCode">Booking Type Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.especialtyCode">Especialty Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.doctorId">Doctor Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.patientId">Patient Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.referralCode">Referral Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.appointmentStatusCode">Appointment Status Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.doctorCharge">Doctor Charge</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.subscriber">Subscriber</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.claim">Claim</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.appointments.appliedCategory">Applied Category</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {appointmentsList.map((appointments, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/appointments/${appointments.id}`} color="link" size="sm">
                      {appointments.id}
                    </Button>
                  </td>
                  <td>{appointments.appointmentDate}</td>
                  <td>{appointments.phoneNo}</td>
                  <td>{appointments.email}</td>
                  <td>{appointments.appointmentTypeCode}</td>
                  <td>{appointments.apptStartTime}</td>
                  <td>{appointments.apptEndTime}</td>
                  <td>{appointments.appointmentAgenda}</td>
                  <td>{appointments.consultationCharge}</td>
                  <td>{appointments.primaryAppointmentNumber}</td>
                  <td>{appointments.capturedDtm}</td>
                  <td>{appointments.appointmentRemarks}</td>
                  <td>{appointments.nextVisitDt}</td>
                  <td>{appointments.createDtm}</td>
                  <td>{appointments.createdBy}</td>
                  <td>{appointments.modifiedBy}</td>
                  <td>{appointments.timestampentry}</td>
                  <td>{appointments.microSec}</td>
                  <td>{appointments.lastupdated}</td>
                  <td>{appointments.active ? 'true' : 'false'}</td>
                  <td>{appointments.cursor}</td>
                  <td>{appointments.createdByType}</td>
                  <td>{appointments.discount}</td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.DiscountType.${appointments.discountType}`} />
                  </td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.PatientType.${appointments.patientType}`} />
                  </td>
                  <td>{appointments.exposed ? 'true' : 'false'}</td>
                  <td>{appointments.referedByName}</td>
                  <td>{appointments.referedByTown}</td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.PaymentMethod.${appointments.paymentMethod}`} />
                  </td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.PaymentStatus.${appointments.paymentStatus}`} />
                  </td>
                  <td>{appointments.transactionRef}</td>
                  <td>{appointments.emergency ? 'true' : 'false'}</td>
                  <td>{appointments.highRisk ? 'true' : 'false'}</td>
                  <td>{appointments.isMedicoLegal ? 'true' : 'false'}</td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.ConsultType.${appointments.consultType}`} />
                  </td>
                  <td>{appointments.checkinTime}</td>
                  <td>{appointments.printed ? 'true' : 'false'}</td>
                  <td>{appointments.printCount}</td>
                  <td>{appointments.appliedCategoryCode}</td>
                  <td>
                    {appointments.scheme ? <Link to={`/payment-scheme/${appointments.scheme.id}`}>{appointments.scheme.id}</Link> : ''}
                  </td>
                  <td>
                    {appointments.admission ? <Link to={`/admission/${appointments.admission.id}`}>{appointments.admission.id}</Link> : ''}
                  </td>
                  <td>
                    {appointments.bookingTypeCode ? (
                      <Link to={`/booking-type/${appointments.bookingTypeCode.id}`}>{appointments.bookingTypeCode.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {appointments.especialtyCode ? (
                      <Link to={`/especialty/${appointments.especialtyCode.id}`}>{appointments.especialtyCode.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{appointments.doctorId ? <Link to={`/doctor/${appointments.doctorId.id}`}>{appointments.doctorId.id}</Link> : ''}</td>
                  <td>
                    {appointments.patientId ? <Link to={`/gluco-user/${appointments.patientId.id}`}>{appointments.patientId.id}</Link> : ''}
                  </td>
                  <td>
                    {appointments.referralCode ? (
                      <Link to={`/referral/${appointments.referralCode.id}`}>{appointments.referralCode.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {appointments.appointmentStatusCode ? (
                      <Link to={`/appointment-status/${appointments.appointmentStatusCode.id}`}>
                        {appointments.appointmentStatusCode.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {appointments.doctorCharge ? (
                      <Link to={`/doctor-charges/${appointments.doctorCharge.id}`}>{appointments.doctorCharge.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {appointments.subscriber ? (
                      <Link to={`/package-subscriber/${appointments.subscriber.id}`}>{appointments.subscriber.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{appointments.claim ? <Link to={`/insurance-claim/${appointments.claim.id}`}>{appointments.claim.id}</Link> : ''}</td>
                  <td>
                    {appointments.appliedCategory ? (
                      <Link to={`/hospital-patient-attribute/${appointments.appliedCategory.id}`}>{appointments.appliedCategory.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/appointments/${appointments.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/appointments/${appointments.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/appointments/${appointments.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="tinyMedicRestApp.appointments.home.notFound">No Appointments found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={appointmentsList && appointmentsList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Appointments;
