import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MedicalHistory from './medical-history';
import MedicalHistoryDetail from './medical-history-detail';
import MedicalHistoryUpdate from './medical-history-update';
import MedicalHistoryDeleteDialog from './medical-history-delete-dialog';

const MedicalHistoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MedicalHistory />} />
    <Route path="new" element={<MedicalHistoryUpdate />} />
    <Route path=":id">
      <Route index element={<MedicalHistoryDetail />} />
      <Route path="edit" element={<MedicalHistoryUpdate />} />
      <Route path="delete" element={<MedicalHistoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MedicalHistoryRoutes;
