import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, Row, Table } from 'reactstrap';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getPaginationState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, searchEntities } from './hospital-bill.reducer';

export const HospitalBill = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const hospitalBillList = useAppSelector(state => state.hospitalBill.entities);
  const loading = useAppSelector(state => state.hospitalBill.loading);
  const totalItems = useAppSelector(state => state.hospitalBill.totalItems);

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="hospital-bill-heading" data-cy="HospitalBillHeading">
        <Translate contentKey="tinyMedicRestApp.hospitalBill.home.title">Hospital Bills</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="tinyMedicRestApp.hospitalBill.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/hospital-bill/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="tinyMedicRestApp.hospitalBill.home.createLabel">Create new Hospital Bill</Translate>
          </Link>
        </div>
      </h2>
      <Row>
        <Col sm="12">
          <Form onSubmit={startSearching}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  defaultValue={search}
                  onChange={handleSearch}
                  placeholder={translate('tinyMedicRestApp.hospitalBill.home.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <div className="table-responsive">
        {hospitalBillList && hospitalBillList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.id">Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('margin')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.margin">Margin</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('margin')} />
                </th>
                <th className="hand" onClick={sort('total')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.total">Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('total')} />
                </th>
                <th className="hand" onClick={sort('netTotal')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.netTotal">Net Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('netTotal')} />
                </th>
                <th className="hand" onClick={sort('discount')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.discount">Discount</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('discount')} />
                </th>
                <th className="hand" onClick={sort('tax')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.tax">Tax</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('tax')} />
                </th>
                <th className="hand" onClick={sort('taxPlusNetTotal')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.taxPlusNetTotal">Tax Plus Net Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('taxPlusNetTotal')} />
                </th>
                <th className="hand" onClick={sort('discountPercent')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.discountPercent">Discount Percent</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('discountPercent')} />
                </th>
                <th className="hand" onClick={sort('billTotal')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.billTotal">Bill Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('billTotal')} />
                </th>
                <th className="hand" onClick={sort('paidAmount')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.paidAmount">Paid Amount</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('paidAmount')} />
                </th>
                <th className="hand" onClick={sort('balance')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.balance">Balance</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('balance')} />
                </th>
                <th className="hand" onClick={sort('serviceCharge')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.serviceCharge">Service Charge</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('serviceCharge')} />
                </th>
                <th className="hand" onClick={sort('grantTotal')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.grantTotal">Grant Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('grantTotal')} />
                </th>
                <th className="hand" onClick={sort('grnNetTotal')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.grnNetTotal">Grn Net Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('grnNetTotal')} />
                </th>
                <th className="hand" onClick={sort('claimableTotal')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.claimableTotal">Claimable Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('claimableTotal')} />
                </th>
                <th className="hand" onClick={sort('comments')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.comments">Comments</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('comments')} />
                </th>
                <th className="hand" onClick={sort('cancelled')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.cancelled">Cancelled</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('cancelled')} />
                </th>
                <th className="hand" onClick={sort('refunded')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.refunded">Refunded</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('refunded')} />
                </th>
                <th className="hand" onClick={sort('reactivated')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.reactivated">Reactivated</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('reactivated')} />
                </th>
                <th className="hand" onClick={sort('billDate')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.billDate">Bill Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('billDate')} />
                </th>
                <th className="hand" onClick={sort('billTime')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.billTime">Bill Time</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('billTime')} />
                </th>
                <th className="hand" onClick={sort('billOrigin')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.billOrigin">Bill Origin</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('billOrigin')} />
                </th>
                <th className="hand" onClick={sort('billClassType')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.billClassType">Bill Class Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('billClassType')} />
                </th>
                <th className="hand" onClick={sort('printed')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.printed">Printed</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('printed')} />
                </th>
                <th className="hand" onClick={sort('insurancePolicyNo')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.insurancePolicyNo">Insurance Policy No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('insurancePolicyNo')} />
                </th>
                <th className="hand" onClick={sort('active')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.active">Active</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('active')} />
                </th>
                <th className="hand" onClick={sort('microSec')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.microSec">Micro Sec</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('microSec')} />
                </th>
                <th className="hand" onClick={sort('timestampEntry')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.timestampEntry">Timestamp Entry</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('timestampEntry')} />
                </th>
                <th className="hand" onClick={sort('lastUpdated')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.lastUpdated">Last Updated</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastUpdated')} />
                </th>
                <th className="hand" onClick={sort('cursor')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.cursor">Cursor</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('cursor')} />
                </th>
                <th className="hand" onClick={sort('billNumber')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.billNumber">Bill Number</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('billNumber')} />
                </th>
                <th className="hand" onClick={sort('addedByType')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.addedByType">Added By Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('addedByType')} />
                </th>
                <th className="hand" onClick={sort('addedByID')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.addedByID">Added By ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('addedByID')} />
                </th>
                <th className="hand" onClick={sort('modifiedByType')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.modifiedByType">Modified By Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedByType')} />
                </th>
                <th className="hand" onClick={sort('modifiedByID')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.modifiedByID">Modified By ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedByID')} />
                </th>
                <th className="hand" onClick={sort('multiplier')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.multiplier">Multiplier</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('multiplier')} />
                </th>
                <th className="hand" onClick={sort('roundOffTotal')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.roundOffTotal">Round Off Total</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('roundOffTotal')} />
                </th>
                <th className="hand" onClick={sort('printCount')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.printCount">Print Count</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('printCount')} />
                </th>
                <th className="hand" onClick={sort('voided')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.voided">Voided</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('voided')} />
                </th>
                <th className="hand" onClick={sort('voidedReason')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.voidedReason">Voided Reason</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('voidedReason')} />
                </th>
                <th className="hand" onClick={sort('voidedDate')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.voidedDate">Voided Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('voidedDate')} />
                </th>
                <th className="hand" onClick={sort('appliedCategoryCode')}>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.appliedCategoryCode">Applied Category Code</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('appliedCategoryCode')} />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.hospital">Hospital</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.processedBy">Processed By</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.admission">Admission</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.scheme">Scheme</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.patientCategory">Patient Category</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.patient">Patient</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.hospitalBill.appliedCategory">Applied Category</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {hospitalBillList.map((hospitalBill, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/hospital-bill/${hospitalBill.id}`} color="link" size="sm">
                      {hospitalBill.id}
                    </Button>
                  </td>
                  <td>{hospitalBill.margin}</td>
                  <td>{hospitalBill.total}</td>
                  <td>{hospitalBill.netTotal}</td>
                  <td>{hospitalBill.discount}</td>
                  <td>{hospitalBill.tax}</td>
                  <td>{hospitalBill.taxPlusNetTotal}</td>
                  <td>{hospitalBill.discountPercent}</td>
                  <td>{hospitalBill.billTotal}</td>
                  <td>{hospitalBill.paidAmount}</td>
                  <td>{hospitalBill.balance}</td>
                  <td>{hospitalBill.serviceCharge}</td>
                  <td>{hospitalBill.grantTotal}</td>
                  <td>{hospitalBill.grnNetTotal}</td>
                  <td>{hospitalBill.claimableTotal}</td>
                  <td>{hospitalBill.comments}</td>
                  <td>{hospitalBill.cancelled ? 'true' : 'false'}</td>
                  <td>{hospitalBill.refunded ? 'true' : 'false'}</td>
                  <td>{hospitalBill.reactivated ? 'true' : 'false'}</td>
                  <td>
                    {hospitalBill.billDate ? <TextFormat type="date" value={hospitalBill.billDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {hospitalBill.billTime ? <TextFormat type="date" value={hospitalBill.billTime} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.BillOrigin.${hospitalBill.billOrigin}`} />
                  </td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.BillClassType.${hospitalBill.billClassType}`} />
                  </td>
                  <td>{hospitalBill.printed ? 'true' : 'false'}</td>
                  <td>{hospitalBill.insurancePolicyNo}</td>
                  <td>{hospitalBill.active ? 'true' : 'false'}</td>
                  <td>
                    {hospitalBill.microSec ? <TextFormat type="date" value={hospitalBill.microSec} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {hospitalBill.timestampEntry ? (
                      <TextFormat type="date" value={hospitalBill.timestampEntry} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {hospitalBill.lastUpdated ? <TextFormat type="date" value={hospitalBill.lastUpdated} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{hospitalBill.cursor}</td>
                  <td>{hospitalBill.billNumber}</td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.AddedByType.${hospitalBill.addedByType}`} />
                  </td>
                  <td>{hospitalBill.addedByID}</td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.AddedByType.${hospitalBill.modifiedByType}`} />
                  </td>
                  <td>{hospitalBill.modifiedByID}</td>
                  <td>{hospitalBill.multiplier}</td>
                  <td>{hospitalBill.roundOffTotal}</td>
                  <td>{hospitalBill.printCount}</td>
                  <td>{hospitalBill.voided ? 'true' : 'false'}</td>
                  <td>{hospitalBill.voidedReason}</td>
                  <td>{hospitalBill.voidedDate}</td>
                  <td>{hospitalBill.appliedCategoryCode}</td>
                  <td>
                    {hospitalBill.hospital ? <Link to={`/hospital/${hospitalBill.hospital.id}`}>{hospitalBill.hospital.id}</Link> : ''}
                  </td>
                  <td>
                    {hospitalBill.processedBy ? (
                      <Link to={`/hospital-accountant/${hospitalBill.processedBy.id}`}>{hospitalBill.processedBy.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {hospitalBill.admission ? <Link to={`/admission/${hospitalBill.admission.id}`}>{hospitalBill.admission.id}</Link> : ''}
                  </td>
                  <td>
                    {hospitalBill.scheme ? <Link to={`/payment-scheme/${hospitalBill.scheme.id}`}>{hospitalBill.scheme.id}</Link> : ''}
                  </td>
                  <td>
                    {hospitalBill.patientCategory ? (
                      <Link to={`/patient-category/${hospitalBill.patientCategory.id}`}>{hospitalBill.patientCategory.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {hospitalBill.patient ? <Link to={`/gluco-user/${hospitalBill.patient.id}`}>{hospitalBill.patient.id}</Link> : ''}
                  </td>
                  <td>
                    {hospitalBill.appliedCategory ? (
                      <Link to={`/hospital-patient-attribute/${hospitalBill.appliedCategory.id}`}>{hospitalBill.appliedCategory.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/hospital-bill/${hospitalBill.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/hospital-bill/${hospitalBill.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/hospital-bill/${hospitalBill.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.home.notFound">No Hospital Bills found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={hospitalBillList && hospitalBillList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default HospitalBill;
