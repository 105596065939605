import { INurse } from 'app/shared/model/nurse.model';
import { IAdmission } from 'app/shared/model/admission.model';
import { IPressure } from 'app/shared/model/pressure.model';
import { IOtherVitals } from 'app/shared/model/other-vitals.model';
import { IVitals } from 'app/shared/model/vitals.model';

export interface INursingNotes {
  id?: string;
  date?: number | null;
  active?: boolean;
  timestampentry?: number;
  microSec?: number;
  lastupdated?: number;
  cursor?: number;
  notes?: string;
  nurse?: INurse;
  admission?: IAdmission | null;
  pressure?: IPressure | null;
  otherVitals?: IOtherVitals | null;
  vitals?: IVitals | null;
}

export const defaultValue: Readonly<INursingNotes> = {
  active: false,
};
