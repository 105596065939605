import { IHealthCamp } from 'app/shared/model/health-camp.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { EntityType } from 'app/shared/model/enumerations/entity-type.model';

export interface IHealthCampRegistration {
  id?: string;
  registrationDate?: number;
  organizerType?: keyof typeof EntityType;
  organizerId?: string;
  active?: boolean;
  cursor?: number;
  lastUpdated?: number;
  microSec?: number;
  timestampEntry?: number;
  camp?: IHealthCamp | null;
  patient?: IGlucoUser | null;
}

export const defaultValue: Readonly<IHealthCampRegistration> = {
  active: false,
};
