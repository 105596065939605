import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './pharmacy-bill.reducer';

export const PharmacyBillDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const pharmacyBillEntity = useAppSelector(state => state.pharmacyBill.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="pharmacyBillDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.pharmacyBill.detail.title">PharmacyBill</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.id">Id</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.id}</dd>
          <dt>
            <span id="billNumber">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.billNumber">Bill Number</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.billNumber}</dd>
          <dt>
            <span id="billDate">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.billDate">Bill Date</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.billDate}</dd>
          <dt>
            <span id="totalCost">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.totalCost">Total Cost</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.totalCost}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.discount">Discount</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.discount}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.active">Active</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.cursor}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.timestampentry}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.microSec}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.lastupdated}</dd>
          <dt>
            <span id="cgst">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.cgst">Cgst</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.cgst}</dd>
          <dt>
            <span id="sgst">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.sgst">Sgst</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.sgst}</dd>
          <dt>
            <span id="patientType">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.patientType">Patient Type</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.patientType}</dd>
          <dt>
            <span id="discountType">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.discountType">Discount Type</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.discountType}</dd>
          <dt>
            <span id="discountRemark">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.discountRemark">Discount Remark</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.discountRemark}</dd>
          <dt>
            <span id="verified">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.verified">Verified</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.verified ? 'true' : 'false'}</dd>
          <dt>
            <span id="referedByName">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.referedByName">Refered By Name</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.referedByName}</dd>
          <dt>
            <span id="referralComments">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.referralComments">Referral Comments</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.referralComments}</dd>
          <dt>
            <span id="billFor">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.billFor">Bill For</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.billFor}</dd>
          <dt>
            <span id="orderStatus">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.orderStatus">Order Status</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.orderStatus}</dd>
          <dt>
            <span id="roundOffVal">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.roundOffVal">Round Off Val</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.roundOffVal}</dd>
          <dt>
            <span id="billForId">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.billForId">Bill For Id</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.billForId}</dd>
          <dt>
            <span id="cessAmount">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.cessAmount">Cess Amount</Translate>
            </span>
            <UncontrolledTooltip target="cessAmount">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.cessAmount" />
            </UncontrolledTooltip>
          </dt>
          <dd>{pharmacyBillEntity.cessAmount}</dd>
          <dt>
            <span id="amended">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.amended">Amended</Translate>
            </span>
            <UncontrolledTooltip target="amended">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.amended" />
            </UncontrolledTooltip>
          </dt>
          <dd>{pharmacyBillEntity.amended ? 'true' : 'false'}</dd>
          <dt>
            <span id="printed">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.printed">Printed</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.printed ? 'true' : 'false'}</dd>
          <dt>
            <span id="printCount">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.printCount">Print Count</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.printCount}</dd>
          <dt>
            <span id="physicalReceipt">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.physicalReceipt">Physical Receipt</Translate>
            </span>
            <UncontrolledTooltip target="physicalReceipt">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.physicalReceipt" />
            </UncontrolledTooltip>
          </dt>
          <dd>{pharmacyBillEntity.physicalReceipt}</dd>
          <dt>
            <span id="discountApprovedBy">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.discountApprovedBy">Discount Approved By</Translate>
            </span>
            <UncontrolledTooltip target="discountApprovedBy">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.discountApprovedBy" />
            </UncontrolledTooltip>
          </dt>
          <dd>{pharmacyBillEntity.discountApprovedBy}</dd>
          <dt>
            <span id="voided">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.voided">Voided</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.voided ? 'true' : 'false'}</dd>
          <dt>
            <span id="voidedReason">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.voidedReason">Voided Reason</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.voidedReason}</dd>
          <dt>
            <span id="voidedDate">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.voidedDate">Voided Date</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.voidedDate}</dd>
          <dt>
            <span id="appliedCategoryCode">
              <Translate contentKey="tinyMedicRestApp.pharmacyBill.appliedCategoryCode">Applied Category Code</Translate>
            </span>
          </dt>
          <dd>{pharmacyBillEntity.appliedCategoryCode}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.pharmacy">Pharmacy</Translate>
          </dt>
          <dd>{pharmacyBillEntity.pharmacy ? pharmacyBillEntity.pharmacy.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.glucoUser">Gluco User</Translate>
          </dt>
          <dd>{pharmacyBillEntity.glucoUser ? pharmacyBillEntity.glucoUser.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.pharmacist">Pharmacist</Translate>
          </dt>
          <dd>{pharmacyBillEntity.pharmacist ? pharmacyBillEntity.pharmacist.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.admission">Admission</Translate>
          </dt>
          <dd>{pharmacyBillEntity.admission ? pharmacyBillEntity.admission.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.billPriscription">Bill Priscription</Translate>
          </dt>
          <dd>{pharmacyBillEntity.billPriscription ? pharmacyBillEntity.billPriscription.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.scheme">Scheme</Translate>
          </dt>
          <dd>{pharmacyBillEntity.scheme ? pharmacyBillEntity.scheme.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.patientCategory">Patient Category</Translate>
          </dt>
          <dd>{pharmacyBillEntity.patientCategory ? pharmacyBillEntity.patientCategory.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.consumer">Consumer</Translate>
          </dt>
          <dd>{pharmacyBillEntity.consumer ? pharmacyBillEntity.consumer.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.amendedFrom">Amended From</Translate>
          </dt>
          <dd>{pharmacyBillEntity.amendedFrom ? pharmacyBillEntity.amendedFrom.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.amendedTo">Amended To</Translate>
          </dt>
          <dd>{pharmacyBillEntity.amendedTo ? pharmacyBillEntity.amendedTo.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.invoiceType">Invoice Type</Translate>
          </dt>
          <dd>{pharmacyBillEntity.invoiceType ? pharmacyBillEntity.invoiceType.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.appliedCategory">Applied Category</Translate>
          </dt>
          <dd>{pharmacyBillEntity.appliedCategory ? pharmacyBillEntity.appliedCategory.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/pharmacy-bill" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/pharmacy-bill/${pharmacyBillEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PharmacyBillDetail;
