import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './nurse-department.reducer';

export const NurseDepartmentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const nurseDepartmentEntity = useAppSelector(state => state.nurseDepartment.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="nurseDepartmentDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.nurseDepartment.detail.title">NurseDepartment</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.id">Id</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.id}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.sortOrder}</dd>
          <dt>
            <span id="skillLevel">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.skillLevel">Skill Level</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.skillLevel}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.active">Active</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.cursor}</dd>
          <dt>
            <span id="lastUpdate">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.lastUpdate">Last Update</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.lastUpdate}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.nurseDepartment.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{nurseDepartmentEntity.timestampEntry}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.nurseDepartment.nurse">Nurse</Translate>
          </dt>
          <dd>{nurseDepartmentEntity.nurse ? nurseDepartmentEntity.nurse.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.nurseDepartment.department">Department</Translate>
          </dt>
          <dd>{nurseDepartmentEntity.department ? nurseDepartmentEntity.department.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/nurse-department" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/nurse-department/${nurseDepartmentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NurseDepartmentDetail;
