import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormText, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getPaymentSchemes } from 'app/entities/payment-scheme/payment-scheme.reducer';
import { getEntities as getAdmissions } from 'app/entities/admission/admission.reducer';
import { getEntities as getBookingTypes } from 'app/entities/booking-type/booking-type.reducer';
import { getEntities as getEspecialties } from 'app/entities/especialty/especialty.reducer';
import { getEntities as getDoctors } from 'app/entities/doctor/doctor.reducer';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { getEntities as getReferrals } from 'app/entities/referral/referral.reducer';
import { getEntities as getAppointmentStatuses } from 'app/entities/appointment-status/appointment-status.reducer';
import { getEntities as getDoctorCharges } from 'app/entities/doctor-charges/doctor-charges.reducer';
import { getEntities as getPackageSubscribers } from 'app/entities/package-subscriber/package-subscriber.reducer';
import { getEntities as getInsuranceClaims } from 'app/entities/insurance-claim/insurance-claim.reducer';
import { getEntities as getHospitalPatientAttributes } from 'app/entities/hospital-patient-attribute/hospital-patient-attribute.reducer';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { PatientType } from 'app/shared/model/enumerations/patient-type.model';
import { PaymentMethod } from 'app/shared/model/enumerations/payment-method.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { ConsultType } from 'app/shared/model/enumerations/consult-type.model';
import { createEntity, getEntity, reset, updateEntity } from './appointments.reducer';

export const AppointmentsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const paymentSchemes = useAppSelector(state => state.paymentScheme.entities);
  const admissions = useAppSelector(state => state.admission.entities);
  const bookingTypes = useAppSelector(state => state.bookingType.entities);
  const especialties = useAppSelector(state => state.especialty.entities);
  const doctors = useAppSelector(state => state.doctor.entities);
  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const referrals = useAppSelector(state => state.referral.entities);
  const appointmentStatuses = useAppSelector(state => state.appointmentStatus.entities);
  const doctorCharges = useAppSelector(state => state.doctorCharges.entities);
  const packageSubscribers = useAppSelector(state => state.packageSubscriber.entities);
  const insuranceClaims = useAppSelector(state => state.insuranceClaim.entities);
  const hospitalPatientAttributes = useAppSelector(state => state.hospitalPatientAttribute.entities);
  const appointmentsEntity = useAppSelector(state => state.appointments.entity);
  const loading = useAppSelector(state => state.appointments.loading);
  const updating = useAppSelector(state => state.appointments.updating);
  const updateSuccess = useAppSelector(state => state.appointments.updateSuccess);
  const discountTypeValues = Object.keys(DiscountType);
  const patientTypeValues = Object.keys(PatientType);
  const paymentMethodValues = Object.keys(PaymentMethod);
  const paymentStatusValues = Object.keys(PaymentStatus);
  const consultTypeValues = Object.keys(ConsultType);

  const handleClose = () => {
    navigate(`/appointments${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPaymentSchemes({}));
    dispatch(getAdmissions({}));
    dispatch(getBookingTypes({}));
    dispatch(getEspecialties({}));
    dispatch(getDoctors({}));
    dispatch(getGlucoUsers({}));
    dispatch(getReferrals({}));
    dispatch(getAppointmentStatuses({}));
    dispatch(getDoctorCharges({}));
    dispatch(getPackageSubscribers({}));
    dispatch(getInsuranceClaims({}));
    dispatch(getHospitalPatientAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.appointmentDate !== undefined && typeof values.appointmentDate !== 'number') {
      values.appointmentDate = Number(values.appointmentDate);
    }
    if (values.consultationCharge !== undefined && typeof values.consultationCharge !== 'number') {
      values.consultationCharge = Number(values.consultationCharge);
    }
    if (values.capturedDtm !== undefined && typeof values.capturedDtm !== 'number') {
      values.capturedDtm = Number(values.capturedDtm);
    }
    if (values.nextVisitDt !== undefined && typeof values.nextVisitDt !== 'number') {
      values.nextVisitDt = Number(values.nextVisitDt);
    }
    if (values.createDtm !== undefined && typeof values.createDtm !== 'number') {
      values.createDtm = Number(values.createDtm);
    }
    if (values.timestampentry !== undefined && typeof values.timestampentry !== 'number') {
      values.timestampentry = Number(values.timestampentry);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.lastupdated !== undefined && typeof values.lastupdated !== 'number') {
      values.lastupdated = Number(values.lastupdated);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.discount !== undefined && typeof values.discount !== 'number') {
      values.discount = Number(values.discount);
    }
    if (values.checkinTime !== undefined && typeof values.checkinTime !== 'number') {
      values.checkinTime = Number(values.checkinTime);
    }
    if (values.printCount !== undefined && typeof values.printCount !== 'number') {
      values.printCount = Number(values.printCount);
    }

    const entity = {
      ...appointmentsEntity,
      ...values,
      scheme: paymentSchemes.find(it => it.id.toString() === values.scheme?.toString()),
      admission: admissions.find(it => it.id.toString() === values.admission?.toString()),
      bookingTypeCode: bookingTypes.find(it => it.id.toString() === values.bookingTypeCode?.toString()),
      especialtyCode: especialties.find(it => it.id.toString() === values.especialtyCode?.toString()),
      doctorId: doctors.find(it => it.id.toString() === values.doctorId?.toString()),
      patientId: glucoUsers.find(it => it.id.toString() === values.patientId?.toString()),
      referralCode: referrals.find(it => it.id.toString() === values.referralCode?.toString()),
      appointmentStatusCode: appointmentStatuses.find(it => it.id.toString() === values.appointmentStatusCode?.toString()),
      doctorCharge: doctorCharges.find(it => it.id.toString() === values.doctorCharge?.toString()),
      subscriber: packageSubscribers.find(it => it.id.toString() === values.subscriber?.toString()),
      claim: insuranceClaims.find(it => it.id.toString() === values.claim?.toString()),
      appliedCategory: hospitalPatientAttributes.find(it => it.id.toString() === values.appliedCategory?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          discountType: 'NONE',
          patientType: 'OP',
          paymentMethod: 'CASH',
          paymentStatus: 'ENTERED',
          consultType: 'VIRTUAL',
          ...appointmentsEntity,
          scheme: appointmentsEntity?.scheme?.id,
          admission: appointmentsEntity?.admission?.id,
          bookingTypeCode: appointmentsEntity?.bookingTypeCode?.id,
          especialtyCode: appointmentsEntity?.especialtyCode?.id,
          doctorId: appointmentsEntity?.doctorId?.id,
          patientId: appointmentsEntity?.patientId?.id,
          referralCode: appointmentsEntity?.referralCode?.id,
          appointmentStatusCode: appointmentsEntity?.appointmentStatusCode?.id,
          doctorCharge: appointmentsEntity?.doctorCharge?.id,
          subscriber: appointmentsEntity?.subscriber?.id,
          claim: appointmentsEntity?.claim?.id,
          appliedCategory: appointmentsEntity?.appliedCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.appointments.home.createOrEditLabel" data-cy="AppointmentsCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.appointments.home.createOrEditLabel">Create or edit a Appointments</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="appointments-id"
                  label={translate('tinyMedicRestApp.appointments.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.appointmentDate')}
                id="appointments-appointmentDate"
                name="appointmentDate"
                data-cy="appointmentDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.phoneNo')}
                id="appointments-phoneNo"
                name="phoneNo"
                data-cy="phoneNo"
                type="text"
                validate={{
                  maxLength: { value: 15, message: translate('entity.validation.maxlength', { max: 15 }) },
                }}
              />
              <UncontrolledTooltip target="phoneNoLabel">
                <Translate contentKey="tinyMedicRestApp.appointments.help.phoneNo" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.email')}
                id="appointments-email"
                name="email"
                data-cy="email"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <UncontrolledTooltip target="emailLabel">
                <Translate contentKey="tinyMedicRestApp.appointments.help.email" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.appointmentTypeCode')}
                id="appointments-appointmentTypeCode"
                name="appointmentTypeCode"
                data-cy="appointmentTypeCode"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.apptStartTime')}
                id="appointments-apptStartTime"
                name="apptStartTime"
                data-cy="apptStartTime"
                type="text"
                validate={{
                  maxLength: { value: 8, message: translate('entity.validation.maxlength', { max: 8 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.apptEndTime')}
                id="appointments-apptEndTime"
                name="apptEndTime"
                data-cy="apptEndTime"
                type="text"
                validate={{
                  maxLength: { value: 8, message: translate('entity.validation.maxlength', { max: 8 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.appointmentAgenda')}
                id="appointments-appointmentAgenda"
                name="appointmentAgenda"
                data-cy="appointmentAgenda"
                type="text"
                validate={{
                  maxLength: { value: 225, message: translate('entity.validation.maxlength', { max: 225 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.consultationCharge')}
                id="appointments-consultationCharge"
                name="consultationCharge"
                data-cy="consultationCharge"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.primaryAppointmentNumber')}
                id="appointments-primaryAppointmentNumber"
                name="primaryAppointmentNumber"
                data-cy="primaryAppointmentNumber"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.capturedDtm')}
                id="appointments-capturedDtm"
                name="capturedDtm"
                data-cy="capturedDtm"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.appointmentRemarks')}
                id="appointments-appointmentRemarks"
                name="appointmentRemarks"
                data-cy="appointmentRemarks"
                type="text"
                validate={{
                  maxLength: { value: 225, message: translate('entity.validation.maxlength', { max: 225 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.nextVisitDt')}
                id="appointments-nextVisitDt"
                name="nextVisitDt"
                data-cy="nextVisitDt"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.createDtm')}
                id="appointments-createDtm"
                name="createDtm"
                data-cy="createDtm"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.createdBy')}
                id="appointments-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
                validate={{
                  maxLength: { value: 45, message: translate('entity.validation.maxlength', { max: 45 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.modifiedBy')}
                id="appointments-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
                validate={{
                  maxLength: { value: 45, message: translate('entity.validation.maxlength', { max: 45 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.timestampentry')}
                id="appointments-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.microSec')}
                id="appointments-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.lastupdated')}
                id="appointments-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.active')}
                id="appointments-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.cursor')}
                id="appointments-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.createdByType')}
                id="appointments-createdByType"
                name="createdByType"
                data-cy="createdByType"
                type="text"
                validate={{
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.discount')}
                id="appointments-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.discountType')}
                id="appointments-discountType"
                name="discountType"
                data-cy="discountType"
                type="select"
              >
                {discountTypeValues.map(discountType => (
                  <option value={discountType} key={discountType}>
                    {translate(`tinyMedicRestApp.DiscountType.${discountType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.patientType')}
                id="appointments-patientType"
                name="patientType"
                data-cy="patientType"
                type="select"
              >
                {patientTypeValues.map(patientType => (
                  <option value={patientType} key={patientType}>
                    {translate(`tinyMedicRestApp.PatientType.${patientType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.exposed')}
                id="appointments-exposed"
                name="exposed"
                data-cy="exposed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.referedByName')}
                id="appointments-referedByName"
                name="referedByName"
                data-cy="referedByName"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.referedByTown')}
                id="appointments-referedByTown"
                name="referedByTown"
                data-cy="referedByTown"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.paymentMethod')}
                id="appointments-paymentMethod"
                name="paymentMethod"
                data-cy="paymentMethod"
                type="select"
              >
                {paymentMethodValues.map(paymentMethod => (
                  <option value={paymentMethod} key={paymentMethod}>
                    {translate(`tinyMedicRestApp.PaymentMethod.${paymentMethod}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.paymentStatus')}
                id="appointments-paymentStatus"
                name="paymentStatus"
                data-cy="paymentStatus"
                type="select"
              >
                {paymentStatusValues.map(paymentStatus => (
                  <option value={paymentStatus} key={paymentStatus}>
                    {translate(`tinyMedicRestApp.PaymentStatus.${paymentStatus}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.transactionRef')}
                id="appointments-transactionRef"
                name="transactionRef"
                data-cy="transactionRef"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.emergency')}
                id="appointments-emergency"
                name="emergency"
                data-cy="emergency"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.highRisk')}
                id="appointments-highRisk"
                name="highRisk"
                data-cy="highRisk"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.isMedicoLegal')}
                id="appointments-isMedicoLegal"
                name="isMedicoLegal"
                data-cy="isMedicoLegal"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.consultType')}
                id="appointments-consultType"
                name="consultType"
                data-cy="consultType"
                type="select"
              >
                {consultTypeValues.map(consultType => (
                  <option value={consultType} key={consultType}>
                    {translate(`tinyMedicRestApp.ConsultType.${consultType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.checkinTime')}
                id="appointments-checkinTime"
                name="checkinTime"
                data-cy="checkinTime"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.printed')}
                id="appointments-printed"
                name="printed"
                data-cy="printed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.printCount')}
                id="appointments-printCount"
                name="printCount"
                data-cy="printCount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.appointments.appliedCategoryCode')}
                id="appointments-appliedCategoryCode"
                name="appliedCategoryCode"
                data-cy="appliedCategoryCode"
                type="text"
              />
              <ValidatedField
                id="appointments-scheme"
                name="scheme"
                data-cy="scheme"
                label={translate('tinyMedicRestApp.appointments.scheme')}
                type="select"
              >
                <option value="" key="0" />
                {paymentSchemes
                  ? paymentSchemes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointments-admission"
                name="admission"
                data-cy="admission"
                label={translate('tinyMedicRestApp.appointments.admission')}
                type="select"
              >
                <option value="" key="0" />
                {admissions
                  ? admissions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointments-bookingTypeCode"
                name="bookingTypeCode"
                data-cy="bookingTypeCode"
                label={translate('tinyMedicRestApp.appointments.bookingTypeCode')}
                type="select"
                required
              >
                <option value="" key="0" />
                {bookingTypes
                  ? bookingTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="appointments-especialtyCode"
                name="especialtyCode"
                data-cy="especialtyCode"
                label={translate('tinyMedicRestApp.appointments.especialtyCode')}
                type="select"
                required
              >
                <option value="" key="0" />
                {especialties
                  ? especialties.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="appointments-doctorId"
                name="doctorId"
                data-cy="doctorId"
                label={translate('tinyMedicRestApp.appointments.doctorId')}
                type="select"
                required
              >
                <option value="" key="0" />
                {doctors
                  ? doctors.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="appointments-patientId"
                name="patientId"
                data-cy="patientId"
                label={translate('tinyMedicRestApp.appointments.patientId')}
                type="select"
                required
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="appointments-referralCode"
                name="referralCode"
                data-cy="referralCode"
                label={translate('tinyMedicRestApp.appointments.referralCode')}
                type="select"
                required
              >
                <option value="" key="0" />
                {referrals
                  ? referrals.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="appointments-appointmentStatusCode"
                name="appointmentStatusCode"
                data-cy="appointmentStatusCode"
                label={translate('tinyMedicRestApp.appointments.appointmentStatusCode')}
                type="select"
                required
              >
                <option value="" key="0" />
                {appointmentStatuses
                  ? appointmentStatuses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="appointments-doctorCharge"
                name="doctorCharge"
                data-cy="doctorCharge"
                label={translate('tinyMedicRestApp.appointments.doctorCharge')}
                type="select"
              >
                <option value="" key="0" />
                {doctorCharges
                  ? doctorCharges.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointments-subscriber"
                name="subscriber"
                data-cy="subscriber"
                label={translate('tinyMedicRestApp.appointments.subscriber')}
                type="select"
              >
                <option value="" key="0" />
                {packageSubscribers
                  ? packageSubscribers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointments-claim"
                name="claim"
                data-cy="claim"
                label={translate('tinyMedicRestApp.appointments.claim')}
                type="select"
              >
                <option value="" key="0" />
                {insuranceClaims
                  ? insuranceClaims.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointments-appliedCategory"
                name="appliedCategory"
                data-cy="appliedCategory"
                label={translate('tinyMedicRestApp.appointments.appliedCategory')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalPatientAttributes
                  ? hospitalPatientAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/appointments" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentsUpdate;
