import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { IAdmission } from 'app/shared/model/admission.model';
import { IBookingType } from 'app/shared/model/booking-type.model';
import { IEspecialty } from 'app/shared/model/especialty.model';
import { IDoctor } from 'app/shared/model/doctor.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IReferral } from 'app/shared/model/referral.model';
import { IAppointmentStatus } from 'app/shared/model/appointment-status.model';
import { IDoctorCharges } from 'app/shared/model/doctor-charges.model';
import { IPackageSubscriber } from 'app/shared/model/package-subscriber.model';
import { IInsuranceClaim } from 'app/shared/model/insurance-claim.model';
import { IHospitalPatientAttribute } from 'app/shared/model/hospital-patient-attribute.model';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { PatientType } from 'app/shared/model/enumerations/patient-type.model';
import { PaymentMethod } from 'app/shared/model/enumerations/payment-method.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { ConsultType } from 'app/shared/model/enumerations/consult-type.model';

export interface IAppointments {
  id?: string;
  appointmentDate?: number;
  phoneNo?: string | null;
  email?: string | null;
  appointmentTypeCode?: string;
  apptStartTime?: string | null;
  apptEndTime?: string | null;
  appointmentAgenda?: string | null;
  consultationCharge?: number | null;
  primaryAppointmentNumber?: string | null;
  capturedDtm?: number | null;
  appointmentRemarks?: string | null;
  nextVisitDt?: number | null;
  createDtm?: number | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  timestampentry?: number;
  microSec?: number;
  lastupdated?: number;
  active?: boolean;
  cursor?: number;
  createdByType?: string | null;
  discount?: number | null;
  discountType?: keyof typeof DiscountType | null;
  patientType?: keyof typeof PatientType | null;
  exposed?: boolean | null;
  referedByName?: string | null;
  referedByTown?: string | null;
  paymentMethod?: keyof typeof PaymentMethod | null;
  paymentStatus?: keyof typeof PaymentStatus | null;
  transactionRef?: string | null;
  emergency?: boolean | null;
  highRisk?: boolean | null;
  isMedicoLegal?: boolean;
  consultType?: keyof typeof ConsultType | null;
  checkinTime?: number | null;
  printed?: boolean | null;
  printCount?: number | null;
  appliedCategoryCode?: string | null;
  scheme?: IPaymentScheme | null;
  admission?: IAdmission | null;
  bookingTypeCode?: IBookingType;
  especialtyCode?: IEspecialty;
  doctorId?: IDoctor;
  patientId?: IGlucoUser;
  referralCode?: IReferral;
  appointmentStatusCode?: IAppointmentStatus;
  doctorCharge?: IDoctorCharges | null;
  subscriber?: IPackageSubscriber | null;
  claim?: IInsuranceClaim | null;
  appliedCategory?: IHospitalPatientAttribute | null;
}

export const defaultValue: Readonly<IAppointments> = {
  active: false,
  exposed: false,
  emergency: false,
  highRisk: false,
  isMedicoLegal: false,
  printed: false,
};
