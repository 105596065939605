import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import VolunteerVisits from './volunteer-visits';
import VolunteerVisitsDetail from './volunteer-visits-detail';
import VolunteerVisitsUpdate from './volunteer-visits-update';
import VolunteerVisitsDeleteDialog from './volunteer-visits-delete-dialog';

const VolunteerVisitsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<VolunteerVisits />} />
    <Route path="new" element={<VolunteerVisitsUpdate />} />
    <Route path=":id">
      <Route index element={<VolunteerVisitsDetail />} />
      <Route path="edit" element={<VolunteerVisitsUpdate />} />
      <Route path="delete" element={<VolunteerVisitsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default VolunteerVisitsRoutes;
