import dayjs from 'dayjs';
import { IHospital } from 'app/shared/model/hospital.model';
import { IHospitalAccountant } from 'app/shared/model/hospital-accountant.model';
import { IAdmission } from 'app/shared/model/admission.model';
import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { IPatientCategory } from 'app/shared/model/patient-category.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IHospitalPatientAttribute } from 'app/shared/model/hospital-patient-attribute.model';
import { BillOrigin } from 'app/shared/model/enumerations/bill-origin.model';
import { BillClassType } from 'app/shared/model/enumerations/bill-class-type.model';
import { AddedByType } from 'app/shared/model/enumerations/added-by-type.model';

export interface IHospitalBill {
  id?: string;
  margin?: number | null;
  total?: number | null;
  netTotal?: number | null;
  discount?: number | null;
  tax?: number | null;
  taxPlusNetTotal?: number | null;
  discountPercent?: number | null;
  billTotal?: number | null;
  paidAmount?: number | null;
  balance?: number | null;
  serviceCharge?: number | null;
  grantTotal?: number | null;
  grnNetTotal?: number | null;
  claimableTotal?: number | null;
  comments?: string | null;
  cancelled?: boolean | null;
  refunded?: boolean | null;
  reactivated?: boolean | null;
  billDate?: dayjs.Dayjs | null;
  billTime?: dayjs.Dayjs | null;
  billOrigin?: keyof typeof BillOrigin | null;
  billClassType?: keyof typeof BillClassType | null;
  printed?: boolean | null;
  insurancePolicyNo?: string | null;
  active?: boolean | null;
  microSec?: dayjs.Dayjs | null;
  timestampEntry?: dayjs.Dayjs | null;
  lastUpdated?: dayjs.Dayjs | null;
  cursor?: number;
  billNumber?: string;
  addedByType?: keyof typeof AddedByType | null;
  addedByID?: string | null;
  modifiedByType?: keyof typeof AddedByType | null;
  modifiedByID?: string | null;
  multiplier?: number | null;
  roundOffTotal?: number | null;
  printCount?: number | null;
  voided?: boolean | null;
  voidedReason?: string | null;
  voidedDate?: number | null;
  appliedCategoryCode?: string | null;
  hospital?: IHospital | null;
  processedBy?: IHospitalAccountant | null;
  admission?: IAdmission | null;
  scheme?: IPaymentScheme | null;
  patientCategory?: IPatientCategory | null;
  patient?: IGlucoUser | null;
  appliedCategory?: IHospitalPatientAttribute | null;
}

export const defaultValue: Readonly<IHospitalBill> = {
  cancelled: false,
  refunded: false,
  reactivated: false,
  printed: false,
  active: false,
  voided: false,
};
