import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DiaglabBill from './diaglab-bill';
import DiaglabBillDetail from './diaglab-bill-detail';
import DiaglabBillUpdate from './diaglab-bill-update';
import DiaglabBillDeleteDialog from './diaglab-bill-delete-dialog';

const DiaglabBillRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DiaglabBill />} />
    <Route path="new" element={<DiaglabBillUpdate />} />
    <Route path=":id">
      <Route index element={<DiaglabBillDetail />} />
      <Route path="edit" element={<DiaglabBillUpdate />} />
      <Route path="delete" element={<DiaglabBillDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DiaglabBillRoutes;
