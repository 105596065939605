export enum OrderStatus {
  CREATED = 'Created',

  ASSIGNED = 'Assigned',

  IN_PROGRESS = 'In Progress',

  FINISHED = 'Finished',

  DELIVERED = 'Delivered',

  CLOSED = 'Closed',

  CANCELLED = 'Cancelled',
}
