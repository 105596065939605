export enum AddedByType {
  DOCTOR = 'DOCTOR',

  NURSE = 'NURSE',

  MANAGER = 'MANAGER',

  LAB_ASSISTANT = 'LAB_ASSISTANT',

  HOSPITAL_ASSISSTANT = 'HOSPITAL_ASSISSTANT',

  LAB = 'LAB',

  ACCOUNTANT = 'ACCOUNTANT',
}
