import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormText, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getPreAssessments } from 'app/entities/pre-assessment/pre-assessment.reducer';
import { getEntities as getDoctors } from 'app/entities/doctor/doctor.reducer';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { getEntities as getHospitals } from 'app/entities/hospital/hospital.reducer';
import { getEntities as getAdmissionAdvices } from 'app/entities/admission-advice/admission-advice.reducer';
import { getEntities as getContactDetails } from 'app/entities/contact-details/contact-details.reducer';
import { getEntities as getPaymentSchemes } from 'app/entities/payment-scheme/payment-scheme.reducer';
import { getEntities as getEspecialties } from 'app/entities/especialty/especialty.reducer';
import { getEntities as getHospitalPatientAttributes } from 'app/entities/hospital-patient-attribute/hospital-patient-attribute.reducer';
import { AdmissionStatus } from 'app/shared/model/enumerations/admission-status.model';
import { AdmissionType } from 'app/shared/model/enumerations/admission-type.model';
import { ArrivalMode } from 'app/shared/model/enumerations/arrival-mode.model';
import { createEntity, getEntity, reset, updateEntity } from './admission.reducer';

export const AdmissionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const preAssessments = useAppSelector(state => state.preAssessment.entities);
  const doctors = useAppSelector(state => state.doctor.entities);
  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const hospitals = useAppSelector(state => state.hospital.entities);
  const admissionAdvices = useAppSelector(state => state.admissionAdvice.entities);
  const contactDetails = useAppSelector(state => state.contactDetails.entities);
  const paymentSchemes = useAppSelector(state => state.paymentScheme.entities);
  const especialties = useAppSelector(state => state.especialty.entities);
  const hospitalPatientAttributes = useAppSelector(state => state.hospitalPatientAttribute.entities);
  const admissionEntity = useAppSelector(state => state.admission.entity);
  const loading = useAppSelector(state => state.admission.loading);
  const updating = useAppSelector(state => state.admission.updating);
  const updateSuccess = useAppSelector(state => state.admission.updateSuccess);
  const admissionStatusValues = Object.keys(AdmissionStatus);
  const admissionTypeValues = Object.keys(AdmissionType);
  const arrivalModeValues = Object.keys(ArrivalMode);

  const handleClose = () => {
    navigate(`/admission${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPreAssessments({}));
    dispatch(getDoctors({}));
    dispatch(getGlucoUsers({}));
    dispatch(getHospitals({}));
    dispatch(getAdmissionAdvices({}));
    dispatch(getContactDetails({}));
    dispatch(getPaymentSchemes({}));
    dispatch(getEspecialties({}));
    dispatch(getHospitalPatientAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampentry !== undefined && typeof values.timestampentry !== 'number') {
      values.timestampentry = Number(values.timestampentry);
    }
    if (values.lastupdated !== undefined && typeof values.lastupdated !== 'number') {
      values.lastupdated = Number(values.lastupdated);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.advancePayment !== undefined && typeof values.advancePayment !== 'number') {
      values.advancePayment = Number(values.advancePayment);
    }
    if (values.admissionStart !== undefined && typeof values.admissionStart !== 'number') {
      values.admissionStart = Number(values.admissionStart);
    }
    if (values.admissionEnd !== undefined && typeof values.admissionEnd !== 'number') {
      values.admissionEnd = Number(values.admissionEnd);
    }
    if (values.emergencyStartDate !== undefined && typeof values.emergencyStartDate !== 'number') {
      values.emergencyStartDate = Number(values.emergencyStartDate);
    }
    if (values.highRiskStartDate !== undefined && typeof values.highRiskStartDate !== 'number') {
      values.highRiskStartDate = Number(values.highRiskStartDate);
    }
    if (values.emergencyEndDate !== undefined && typeof values.emergencyEndDate !== 'number') {
      values.emergencyEndDate = Number(values.emergencyEndDate);
    }
    if (values.highRiskEndDate !== undefined && typeof values.highRiskEndDate !== 'number') {
      values.highRiskEndDate = Number(values.highRiskEndDate);
    }

    const entity = {
      ...admissionEntity,
      ...values,
      assessment: preAssessments.find(it => it.id.toString() === values.assessment?.toString()),
      primaryConsultant: doctors.find(it => it.id.toString() === values.primaryConsultant?.toString()),
      patient: glucoUsers.find(it => it.id.toString() === values.patient?.toString()),
      hospital: hospitals.find(it => it.id.toString() === values.hospital?.toString()),
      advice: admissionAdvices.find(it => it.id.toString() === values.advice?.toString()),
      address: contactDetails.find(it => it.id.toString() === values.address?.toString()),
      scheme: paymentSchemes.find(it => it.id.toString() === values.scheme?.toString()),
      especialtyCode: especialties.find(it => it.id.toString() === values.especialtyCode?.toString()),
      appliedCategory: hospitalPatientAttributes.find(it => it.id.toString() === values.appliedCategory?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          status: 'ADMITTED',
          type: 'REGULAR',
          arrivalMode: 'A',
          ...admissionEntity,
          assessment: admissionEntity?.assessment?.id,
          primaryConsultant: admissionEntity?.primaryConsultant?.id,
          patient: admissionEntity?.patient?.id,
          hospital: admissionEntity?.hospital?.id,
          advice: admissionEntity?.advice?.id,
          address: admissionEntity?.address?.id,
          scheme: admissionEntity?.scheme?.id,
          especialtyCode: admissionEntity?.especialtyCode?.id,
          appliedCategory: admissionEntity?.appliedCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.admission.home.createOrEditLabel" data-cy="AdmissionCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.admission.home.createOrEditLabel">Create or edit a Admission</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="admission-id"
                  label={translate('tinyMedicRestApp.admission.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.microSec')}
                id="admission-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.timestampentry')}
                id="admission-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.lastupdated')}
                id="admission-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.cursor')}
                id="admission-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.active')}
                id="admission-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.advancePayment')}
                id="admission-advancePayment"
                name="advancePayment"
                data-cy="advancePayment"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.status')}
                id="admission-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {admissionStatusValues.map(admissionStatus => (
                  <option value={admissionStatus} key={admissionStatus}>
                    {translate(`tinyMedicRestApp.AdmissionStatus.${admissionStatus}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.admissionStart')}
                id="admission-admissionStart"
                name="admissionStart"
                data-cy="admissionStart"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.admissionEnd')}
                id="admission-admissionEnd"
                name="admissionEnd"
                data-cy="admissionEnd"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.admissionNotes')}
                id="admission-admissionNotes"
                name="admissionNotes"
                data-cy="admissionNotes"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.snomedConceptId')}
                id="admission-snomedConceptId"
                name="snomedConceptId"
                data-cy="snomedConceptId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.snomedConceptText')}
                id="admission-snomedConceptText"
                name="snomedConceptText"
                data-cy="snomedConceptText"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.fatherName')}
                id="admission-fatherName"
                name="fatherName"
                data-cy="fatherName"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.ipNumber')}
                id="admission-ipNumber"
                name="ipNumber"
                data-cy="ipNumber"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.emergency')}
                id="admission-emergency"
                name="emergency"
                data-cy="emergency"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.highRisk')}
                id="admission-highRisk"
                name="highRisk"
                data-cy="highRisk"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.emergencyStartDate')}
                id="admission-emergencyStartDate"
                name="emergencyStartDate"
                data-cy="emergencyStartDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.highRiskStartDate')}
                id="admission-highRiskStartDate"
                name="highRiskStartDate"
                data-cy="highRiskStartDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.emergencyEndDate')}
                id="admission-emergencyEndDate"
                name="emergencyEndDate"
                data-cy="emergencyEndDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.highRiskEndDate')}
                id="admission-highRiskEndDate"
                name="highRiskEndDate"
                data-cy="highRiskEndDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.schemeSubscriptionId')}
                id="admission-schemeSubscriptionId"
                name="schemeSubscriptionId"
                data-cy="schemeSubscriptionId"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.isMedicoLegal')}
                id="admission-isMedicoLegal"
                name="isMedicoLegal"
                data-cy="isMedicoLegal"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.type')}
                id="admission-type"
                name="type"
                data-cy="type"
                type="select"
              >
                {admissionTypeValues.map(admissionType => (
                  <option value={admissionType} key={admissionType}>
                    {translate(`tinyMedicRestApp.AdmissionType.${admissionType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.arrivalMode')}
                id="admission-arrivalMode"
                name="arrivalMode"
                data-cy="arrivalMode"
                type="select"
              >
                {arrivalModeValues.map(arrivalMode => (
                  <option value={arrivalMode} key={arrivalMode}>
                    {translate(`tinyMedicRestApp.ArrivalMode.${arrivalMode}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.emAdmissionId')}
                id="admission-emAdmissionId"
                name="emAdmissionId"
                data-cy="emAdmissionId"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.admission.appliedCategoryCode')}
                id="admission-appliedCategoryCode"
                name="appliedCategoryCode"
                data-cy="appliedCategoryCode"
                type="text"
              />
              <ValidatedField
                id="admission-assessment"
                name="assessment"
                data-cy="assessment"
                label={translate('tinyMedicRestApp.admission.assessment')}
                type="select"
              >
                <option value="" key="0" />
                {preAssessments
                  ? preAssessments.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="admission-primaryConsultant"
                name="primaryConsultant"
                data-cy="primaryConsultant"
                label={translate('tinyMedicRestApp.admission.primaryConsultant')}
                type="select"
                required
              >
                <option value="" key="0" />
                {doctors
                  ? doctors.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="admission-patient"
                name="patient"
                data-cy="patient"
                label={translate('tinyMedicRestApp.admission.patient')}
                type="select"
                required
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="admission-hospital"
                name="hospital"
                data-cy="hospital"
                label={translate('tinyMedicRestApp.admission.hospital')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hospitals
                  ? hospitals.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="admission-advice"
                name="advice"
                data-cy="advice"
                label={translate('tinyMedicRestApp.admission.advice')}
                type="select"
              >
                <option value="" key="0" />
                {admissionAdvices
                  ? admissionAdvices.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="admission-address"
                name="address"
                data-cy="address"
                label={translate('tinyMedicRestApp.admission.address')}
                type="select"
              >
                <option value="" key="0" />
                {contactDetails
                  ? contactDetails.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="admission-scheme"
                name="scheme"
                data-cy="scheme"
                label={translate('tinyMedicRestApp.admission.scheme')}
                type="select"
              >
                <option value="" key="0" />
                {paymentSchemes
                  ? paymentSchemes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="admission-especialtyCode"
                name="especialtyCode"
                data-cy="especialtyCode"
                label={translate('tinyMedicRestApp.admission.especialtyCode')}
                type="select"
                required
              >
                <option value="" key="0" />
                {especialties
                  ? especialties.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="admission-appliedCategory"
                name="appliedCategory"
                data-cy="appliedCategory"
                label={translate('tinyMedicRestApp.admission.appliedCategory')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalPatientAttributes
                  ? hospitalPatientAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/admission" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdmissionUpdate;
