import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './health-camp-registration.reducer';

export const HealthCampRegistrationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const healthCampRegistrationEntity = useAppSelector(state => state.healthCampRegistration.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="healthCampRegistrationDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.healthCampRegistration.detail.title">HealthCampRegistration</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.id">Id</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.id}</dd>
          <dt>
            <span id="registrationDate">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.registrationDate">Registration Date</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.registrationDate}</dd>
          <dt>
            <span id="organizerType">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.organizerType">Organizer Type</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.organizerType}</dd>
          <dt>
            <span id="organizerId">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.organizerId">Organizer Id</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.organizerId}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.active">Active</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.healthCampRegistration.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{healthCampRegistrationEntity.timestampEntry}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.healthCampRegistration.camp">Camp</Translate>
          </dt>
          <dd>{healthCampRegistrationEntity.camp ? healthCampRegistrationEntity.camp.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.healthCampRegistration.patient">Patient</Translate>
          </dt>
          <dd>{healthCampRegistrationEntity.patient ? healthCampRegistrationEntity.patient.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/health-camp-registration" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/health-camp-registration/${healthCampRegistrationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default HealthCampRegistrationDetail;
