import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './nursing-notes.reducer';

export const NursingNotesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const nursingNotesEntity = useAppSelector(state => state.nursingNotes.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="nursingNotesDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.nursingNotes.detail.title">NursingNotes</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.id">Id</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.id}</dd>
          <dt>
            <span id="date">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.date">Date</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.date}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.active">Active</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.timestampentry}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.microSec}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.lastupdated}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.cursor}</dd>
          <dt>
            <span id="notes">
              <Translate contentKey="tinyMedicRestApp.nursingNotes.notes">Notes</Translate>
            </span>
          </dt>
          <dd>{nursingNotesEntity.notes}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.nursingNotes.nurse">Nurse</Translate>
          </dt>
          <dd>{nursingNotesEntity.nurse ? nursingNotesEntity.nurse.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.nursingNotes.admission">Admission</Translate>
          </dt>
          <dd>{nursingNotesEntity.admission ? nursingNotesEntity.admission.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.nursingNotes.pressure">Pressure</Translate>
          </dt>
          <dd>{nursingNotesEntity.pressure ? nursingNotesEntity.pressure.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.nursingNotes.otherVitals">Other Vitals</Translate>
          </dt>
          <dd>{nursingNotesEntity.otherVitals ? nursingNotesEntity.otherVitals.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.nursingNotes.vitals">Vitals</Translate>
          </dt>
          <dd>{nursingNotesEntity.vitals ? nursingNotesEntity.vitals.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/nursing-notes" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/nursing-notes/${nursingNotesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NursingNotesDetail;
