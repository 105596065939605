export enum PaymentStatus {
  ENTERED = 'ENTERED',

  REQUESTED = 'REQUESTED',

  COMPLETED = 'COMPLETED',

  REJECTED = 'REJECTED',

  BOUNCED = 'BOUNCED',
}
