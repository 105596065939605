export enum PaymentMethod {
  CASH = 'CASH',

  CREDIT = 'CREDIT',

  INSURANCE = 'INSURANCE',

  CARD = 'CARD',

  CHEQUE = 'CHEQUE',

  SLIP = 'SLIP',

  ONLINESETTLEMENT = 'ONLINESETTLEMENT',
}
