import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { getEntities as getParamedics } from 'app/entities/paramedic/paramedic.reducer';
import { getEntities as getAppointmentStatuses } from 'app/entities/appointment-status/appointment-status.reducer';
import { getEntities as getHospitalPatientAttributes } from 'app/entities/hospital-patient-attribute/hospital-patient-attribute.reducer';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { PaymentMethod } from 'app/shared/model/enumerations/payment-method.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { createEntity, getEntity, reset, updateEntity } from './volunteer-visits.reducer';

export const VolunteerVisitsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const paramedics = useAppSelector(state => state.paramedic.entities);
  const appointmentStatuses = useAppSelector(state => state.appointmentStatus.entities);
  const hospitalPatientAttributes = useAppSelector(state => state.hospitalPatientAttribute.entities);
  const volunteerVisitsEntity = useAppSelector(state => state.volunteerVisits.entity);
  const loading = useAppSelector(state => state.volunteerVisits.loading);
  const updating = useAppSelector(state => state.volunteerVisits.updating);
  const updateSuccess = useAppSelector(state => state.volunteerVisits.updateSuccess);
  const discountTypeValues = Object.keys(DiscountType);
  const paymentMethodValues = Object.keys(PaymentMethod);
  const paymentStatusValues = Object.keys(PaymentStatus);

  const handleClose = () => {
    navigate(`/volunteer-visits${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getGlucoUsers({}));
    dispatch(getParamedics({}));
    dispatch(getAppointmentStatuses({}));
    dispatch(getHospitalPatientAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastupdated !== undefined && typeof values.lastupdated !== 'number') {
      values.lastupdated = Number(values.lastupdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampentry !== undefined && typeof values.timestampentry !== 'number') {
      values.timestampentry = Number(values.timestampentry);
    }
    if (values.capturedDtm !== undefined && typeof values.capturedDtm !== 'number') {
      values.capturedDtm = Number(values.capturedDtm);
    }
    if (values.visitDate !== undefined && typeof values.visitDate !== 'number') {
      values.visitDate = Number(values.visitDate);
    }
    if (values.paidAmount !== undefined && typeof values.paidAmount !== 'number') {
      values.paidAmount = Number(values.paidAmount);
    }
    if (values.discount !== undefined && typeof values.discount !== 'number') {
      values.discount = Number(values.discount);
    }

    const entity = {
      ...volunteerVisitsEntity,
      ...values,
      glucoUser: glucoUsers.find(it => it.id.toString() === values.glucoUser?.toString()),
      paramedic: paramedics.find(it => it.id.toString() === values.paramedic?.toString()),
      appointmentStatus: appointmentStatuses.find(it => it.id.toString() === values.appointmentStatus?.toString()),
      appliedCategory: hospitalPatientAttributes.find(it => it.id.toString() === values.appliedCategory?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          discountType: 'NONE',
          paymentMethod: 'CASH',
          paymentStatus: 'ENTERED',
          ...volunteerVisitsEntity,
          glucoUser: volunteerVisitsEntity?.glucoUser?.id,
          paramedic: volunteerVisitsEntity?.paramedic?.id,
          appointmentStatus: volunteerVisitsEntity?.appointmentStatus?.id,
          appliedCategory: volunteerVisitsEntity?.appliedCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.volunteerVisits.home.createOrEditLabel" data-cy="VolunteerVisitsCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.volunteerVisits.home.createOrEditLabel">Create or edit a VolunteerVisits</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="volunteer-visits-id"
                  label={translate('tinyMedicRestApp.volunteerVisits.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.active')}
                id="volunteer-visits-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.cursor')}
                id="volunteer-visits-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.lastupdated')}
                id="volunteer-visits-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.microSec')}
                id="volunteer-visits-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.timestampentry')}
                id="volunteer-visits-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.visitNotes')}
                id="volunteer-visits-visitNotes"
                name="visitNotes"
                data-cy="visitNotes"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.capturedDtm')}
                id="volunteer-visits-capturedDtm"
                name="capturedDtm"
                data-cy="capturedDtm"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.visitDate')}
                id="volunteer-visits-visitDate"
                name="visitDate"
                data-cy="visitDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.paidAmount')}
                id="volunteer-visits-paidAmount"
                name="paidAmount"
                data-cy="paidAmount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.discount')}
                id="volunteer-visits-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.discountType')}
                id="volunteer-visits-discountType"
                name="discountType"
                data-cy="discountType"
                type="select"
              >
                {discountTypeValues.map(discountType => (
                  <option value={discountType} key={discountType}>
                    {translate(`tinyMedicRestApp.DiscountType.${discountType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.paymentMethod')}
                id="volunteer-visits-paymentMethod"
                name="paymentMethod"
                data-cy="paymentMethod"
                type="select"
              >
                {paymentMethodValues.map(paymentMethod => (
                  <option value={paymentMethod} key={paymentMethod}>
                    {translate(`tinyMedicRestApp.PaymentMethod.${paymentMethod}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.paymentStatus')}
                id="volunteer-visits-paymentStatus"
                name="paymentStatus"
                data-cy="paymentStatus"
                type="select"
              >
                {paymentStatusValues.map(paymentStatus => (
                  <option value={paymentStatus} key={paymentStatus}>
                    {translate(`tinyMedicRestApp.PaymentStatus.${paymentStatus}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.transactionRef')}
                id="volunteer-visits-transactionRef"
                name="transactionRef"
                data-cy="transactionRef"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.volunteerVisits.appliedCategoryCode')}
                id="volunteer-visits-appliedCategoryCode"
                name="appliedCategoryCode"
                data-cy="appliedCategoryCode"
                type="text"
              />
              <ValidatedField
                id="volunteer-visits-glucoUser"
                name="glucoUser"
                data-cy="glucoUser"
                label={translate('tinyMedicRestApp.volunteerVisits.glucoUser')}
                type="select"
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="volunteer-visits-paramedic"
                name="paramedic"
                data-cy="paramedic"
                label={translate('tinyMedicRestApp.volunteerVisits.paramedic')}
                type="select"
              >
                <option value="" key="0" />
                {paramedics
                  ? paramedics.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="volunteer-visits-appointmentStatus"
                name="appointmentStatus"
                data-cy="appointmentStatus"
                label={translate('tinyMedicRestApp.volunteerVisits.appointmentStatus')}
                type="select"
              >
                <option value="" key="0" />
                {appointmentStatuses
                  ? appointmentStatuses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="volunteer-visits-appliedCategory"
                name="appliedCategory"
                data-cy="appliedCategory"
                label={translate('tinyMedicRestApp.volunteerVisits.appliedCategory')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalPatientAttributes
                  ? hospitalPatientAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/volunteer-visits" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default VolunteerVisitsUpdate;
