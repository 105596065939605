import { INurse } from 'app/shared/model/nurse.model';
import { IDepartment } from 'app/shared/model/department.model';

export interface INurseDepartment {
  id?: string;
  sortOrder?: number | null;
  skillLevel?: number | null;
  active?: boolean | null;
  cursor?: number | null;
  lastUpdate?: number | null;
  microSec?: number | null;
  timestampEntry?: number | null;
  nurse?: INurse | null;
  department?: IDepartment | null;
}

export const defaultValue: Readonly<INurseDepartment> = {
  active: false,
};
