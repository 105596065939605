import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NursingNotes from './nursing-notes';
import NursingNotesDetail from './nursing-notes-detail';
import NursingNotesUpdate from './nursing-notes-update';
import NursingNotesDeleteDialog from './nursing-notes-delete-dialog';

const NursingNotesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NursingNotes />} />
    <Route path="new" element={<NursingNotesUpdate />} />
    <Route path=":id">
      <Route index element={<NursingNotesDetail />} />
      <Route path="edit" element={<NursingNotesUpdate />} />
      <Route path="delete" element={<NursingNotesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NursingNotesRoutes;
