import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PharmacyBill from './pharmacy-bill';
import PharmacyBillDetail from './pharmacy-bill-detail';
import PharmacyBillUpdate from './pharmacy-bill-update';
import PharmacyBillDeleteDialog from './pharmacy-bill-delete-dialog';

const PharmacyBillRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PharmacyBill />} />
    <Route path="new" element={<PharmacyBillUpdate />} />
    <Route path=":id">
      <Route index element={<PharmacyBillDetail />} />
      <Route path="edit" element={<PharmacyBillUpdate />} />
      <Route path="delete" element={<PharmacyBillDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PharmacyBillRoutes;
