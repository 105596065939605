import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IParamedic } from 'app/shared/model/paramedic.model';
import { IAppointmentStatus } from 'app/shared/model/appointment-status.model';
import { IHospitalPatientAttribute } from 'app/shared/model/hospital-patient-attribute.model';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { PaymentMethod } from 'app/shared/model/enumerations/payment-method.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';

export interface IVolunteerVisits {
  id?: string;
  active?: boolean;
  cursor?: number;
  lastupdated?: number;
  microSec?: number;
  timestampentry?: number;
  visitNotes?: string | null;
  capturedDtm?: number;
  visitDate?: number;
  paidAmount?: number | null;
  discount?: number | null;
  discountType?: keyof typeof DiscountType | null;
  paymentMethod?: keyof typeof PaymentMethod | null;
  paymentStatus?: keyof typeof PaymentStatus | null;
  transactionRef?: string | null;
  appliedCategoryCode?: string | null;
  glucoUser?: IGlucoUser | null;
  paramedic?: IParamedic | null;
  appointmentStatus?: IAppointmentStatus | null;
  appliedCategory?: IHospitalPatientAttribute | null;
}

export const defaultValue: Readonly<IVolunteerVisits> = {
  active: false,
};
