import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getPharmacies } from 'app/entities/pharmacy/pharmacy.reducer';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { getEntities as getPharmacists } from 'app/entities/pharmacist/pharmacist.reducer';
import { getEntities as getAdmissions } from 'app/entities/admission/admission.reducer';
import { getEntities as getPrescriptions } from 'app/entities/prescription/prescription.reducer';
import { getEntities as getPaymentSchemes } from 'app/entities/payment-scheme/payment-scheme.reducer';
import { getEntities as getPatientCategories } from 'app/entities/patient-category/patient-category.reducer';
import { getEntities as getPharmacyBills } from 'app/entities/pharmacy-bill/pharmacy-bill.reducer';
import { getEntities as getClinicalDictionaries } from 'app/entities/clinical-dictionary/clinical-dictionary.reducer';
import { getEntities as getHospitalPatientAttributes } from 'app/entities/hospital-patient-attribute/hospital-patient-attribute.reducer';
import { PatientType } from 'app/shared/model/enumerations/patient-type.model';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { BillFor } from 'app/shared/model/enumerations/bill-for.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import { createEntity, getEntity, reset, updateEntity } from './pharmacy-bill.reducer';

export const PharmacyBillUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const pharmacies = useAppSelector(state => state.pharmacy.entities);
  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const pharmacists = useAppSelector(state => state.pharmacist.entities);
  const admissions = useAppSelector(state => state.admission.entities);
  const prescriptions = useAppSelector(state => state.prescription.entities);
  const paymentSchemes = useAppSelector(state => state.paymentScheme.entities);
  const patientCategories = useAppSelector(state => state.patientCategory.entities);
  const pharmacyBills = useAppSelector(state => state.pharmacyBill.entities);
  const clinicalDictionaries = useAppSelector(state => state.clinicalDictionary.entities);
  const hospitalPatientAttributes = useAppSelector(state => state.hospitalPatientAttribute.entities);
  const pharmacyBillEntity = useAppSelector(state => state.pharmacyBill.entity);
  const loading = useAppSelector(state => state.pharmacyBill.loading);
  const updating = useAppSelector(state => state.pharmacyBill.updating);
  const updateSuccess = useAppSelector(state => state.pharmacyBill.updateSuccess);
  const patientTypeValues = Object.keys(PatientType);
  const discountTypeValues = Object.keys(DiscountType);
  const billForValues = Object.keys(BillFor);
  const orderStatusValues = Object.keys(OrderStatus);

  const handleClose = () => {
    navigate(`/pharmacy-bill${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPharmacies({}));
    dispatch(getGlucoUsers({}));
    dispatch(getPharmacists({}));
    dispatch(getAdmissions({}));
    dispatch(getPrescriptions({}));
    dispatch(getPaymentSchemes({}));
    dispatch(getPatientCategories({}));
    dispatch(getPharmacyBills({}));
    dispatch(getClinicalDictionaries({}));
    dispatch(getHospitalPatientAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.billDate !== undefined && typeof values.billDate !== 'number') {
      values.billDate = Number(values.billDate);
    }
    if (values.totalCost !== undefined && typeof values.totalCost !== 'number') {
      values.totalCost = Number(values.totalCost);
    }
    if (values.discount !== undefined && typeof values.discount !== 'number') {
      values.discount = Number(values.discount);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.timestampentry !== undefined && typeof values.timestampentry !== 'number') {
      values.timestampentry = Number(values.timestampentry);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.lastupdated !== undefined && typeof values.lastupdated !== 'number') {
      values.lastupdated = Number(values.lastupdated);
    }
    if (values.cgst !== undefined && typeof values.cgst !== 'number') {
      values.cgst = Number(values.cgst);
    }
    if (values.sgst !== undefined && typeof values.sgst !== 'number') {
      values.sgst = Number(values.sgst);
    }
    if (values.roundOffVal !== undefined && typeof values.roundOffVal !== 'number') {
      values.roundOffVal = Number(values.roundOffVal);
    }
    if (values.cessAmount !== undefined && typeof values.cessAmount !== 'number') {
      values.cessAmount = Number(values.cessAmount);
    }
    if (values.printCount !== undefined && typeof values.printCount !== 'number') {
      values.printCount = Number(values.printCount);
    }
    if (values.voidedDate !== undefined && typeof values.voidedDate !== 'number') {
      values.voidedDate = Number(values.voidedDate);
    }

    const entity = {
      ...pharmacyBillEntity,
      ...values,
      pharmacy: pharmacies.find(it => it.id.toString() === values.pharmacy?.toString()),
      glucoUser: glucoUsers.find(it => it.id.toString() === values.glucoUser?.toString()),
      pharmacist: pharmacists.find(it => it.id.toString() === values.pharmacist?.toString()),
      admission: admissions.find(it => it.id.toString() === values.admission?.toString()),
      billPriscription: prescriptions.find(it => it.id.toString() === values.billPriscription?.toString()),
      scheme: paymentSchemes.find(it => it.id.toString() === values.scheme?.toString()),
      patientCategory: patientCategories.find(it => it.id.toString() === values.patientCategory?.toString()),
      consumer: pharmacies.find(it => it.id.toString() === values.consumer?.toString()),
      amendedFrom: pharmacyBills.find(it => it.id.toString() === values.amendedFrom?.toString()),
      amendedTo: pharmacyBills.find(it => it.id.toString() === values.amendedTo?.toString()),
      invoiceType: clinicalDictionaries.find(it => it.id.toString() === values.invoiceType?.toString()),
      appliedCategory: hospitalPatientAttributes.find(it => it.id.toString() === values.appliedCategory?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          patientType: 'OP',
          discountType: 'NONE',
          billFor: 'BUSINESS',
          orderStatus: 'CREATED',
          ...pharmacyBillEntity,
          pharmacy: pharmacyBillEntity?.pharmacy?.id,
          glucoUser: pharmacyBillEntity?.glucoUser?.id,
          pharmacist: pharmacyBillEntity?.pharmacist?.id,
          admission: pharmacyBillEntity?.admission?.id,
          billPriscription: pharmacyBillEntity?.billPriscription?.id,
          scheme: pharmacyBillEntity?.scheme?.id,
          patientCategory: pharmacyBillEntity?.patientCategory?.id,
          consumer: pharmacyBillEntity?.consumer?.id,
          amendedFrom: pharmacyBillEntity?.amendedFrom?.id,
          amendedTo: pharmacyBillEntity?.amendedTo?.id,
          invoiceType: pharmacyBillEntity?.invoiceType?.id,
          appliedCategory: pharmacyBillEntity?.appliedCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.pharmacyBill.home.createOrEditLabel" data-cy="PharmacyBillCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.pharmacyBill.home.createOrEditLabel">Create or edit a PharmacyBill</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="pharmacy-bill-id"
                  label={translate('tinyMedicRestApp.pharmacyBill.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.billNumber')}
                id="pharmacy-bill-billNumber"
                name="billNumber"
                data-cy="billNumber"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.billDate')}
                id="pharmacy-bill-billDate"
                name="billDate"
                data-cy="billDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.totalCost')}
                id="pharmacy-bill-totalCost"
                name="totalCost"
                data-cy="totalCost"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.discount')}
                id="pharmacy-bill-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.active')}
                id="pharmacy-bill-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.cursor')}
                id="pharmacy-bill-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.timestampentry')}
                id="pharmacy-bill-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.microSec')}
                id="pharmacy-bill-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.lastupdated')}
                id="pharmacy-bill-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.cgst')}
                id="pharmacy-bill-cgst"
                name="cgst"
                data-cy="cgst"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.sgst')}
                id="pharmacy-bill-sgst"
                name="sgst"
                data-cy="sgst"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.patientType')}
                id="pharmacy-bill-patientType"
                name="patientType"
                data-cy="patientType"
                type="select"
              >
                {patientTypeValues.map(patientType => (
                  <option value={patientType} key={patientType}>
                    {translate(`tinyMedicRestApp.PatientType.${patientType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.discountType')}
                id="pharmacy-bill-discountType"
                name="discountType"
                data-cy="discountType"
                type="select"
              >
                {discountTypeValues.map(discountType => (
                  <option value={discountType} key={discountType}>
                    {translate(`tinyMedicRestApp.DiscountType.${discountType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.discountRemark')}
                id="pharmacy-bill-discountRemark"
                name="discountRemark"
                data-cy="discountRemark"
                type="text"
                validate={{
                  maxLength: { value: 300, message: translate('entity.validation.maxlength', { max: 300 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.verified')}
                id="pharmacy-bill-verified"
                name="verified"
                data-cy="verified"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.referedByName')}
                id="pharmacy-bill-referedByName"
                name="referedByName"
                data-cy="referedByName"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.referralComments')}
                id="pharmacy-bill-referralComments"
                name="referralComments"
                data-cy="referralComments"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.billFor')}
                id="pharmacy-bill-billFor"
                name="billFor"
                data-cy="billFor"
                type="select"
              >
                {billForValues.map(billFor => (
                  <option value={billFor} key={billFor}>
                    {translate(`tinyMedicRestApp.BillFor.${billFor}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.orderStatus')}
                id="pharmacy-bill-orderStatus"
                name="orderStatus"
                data-cy="orderStatus"
                type="select"
              >
                {orderStatusValues.map(orderStatus => (
                  <option value={orderStatus} key={orderStatus}>
                    {translate(`tinyMedicRestApp.OrderStatus.${orderStatus}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.roundOffVal')}
                id="pharmacy-bill-roundOffVal"
                name="roundOffVal"
                data-cy="roundOffVal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.billForId')}
                id="pharmacy-bill-billForId"
                name="billForId"
                data-cy="billForId"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.cessAmount')}
                id="pharmacy-bill-cessAmount"
                name="cessAmount"
                data-cy="cessAmount"
                type="text"
              />
              <UncontrolledTooltip target="cessAmountLabel">
                <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.cessAmount" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.amended')}
                id="pharmacy-bill-amended"
                name="amended"
                data-cy="amended"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="amendedLabel">
                <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.amended" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.printed')}
                id="pharmacy-bill-printed"
                name="printed"
                data-cy="printed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.printCount')}
                id="pharmacy-bill-printCount"
                name="printCount"
                data-cy="printCount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.physicalReceipt')}
                id="pharmacy-bill-physicalReceipt"
                name="physicalReceipt"
                data-cy="physicalReceipt"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <UncontrolledTooltip target="physicalReceiptLabel">
                <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.physicalReceipt" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.discountApprovedBy')}
                id="pharmacy-bill-discountApprovedBy"
                name="discountApprovedBy"
                data-cy="discountApprovedBy"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="discountApprovedByLabel">
                <Translate contentKey="tinyMedicRestApp.pharmacyBill.help.discountApprovedBy" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.voided')}
                id="pharmacy-bill-voided"
                name="voided"
                data-cy="voided"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.voidedReason')}
                id="pharmacy-bill-voidedReason"
                name="voidedReason"
                data-cy="voidedReason"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.voidedDate')}
                id="pharmacy-bill-voidedDate"
                name="voidedDate"
                data-cy="voidedDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.pharmacyBill.appliedCategoryCode')}
                id="pharmacy-bill-appliedCategoryCode"
                name="appliedCategoryCode"
                data-cy="appliedCategoryCode"
                type="text"
              />
              <ValidatedField
                id="pharmacy-bill-pharmacy"
                name="pharmacy"
                data-cy="pharmacy"
                label={translate('tinyMedicRestApp.pharmacyBill.pharmacy')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacies
                  ? pharmacies.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-glucoUser"
                name="glucoUser"
                data-cy="glucoUser"
                label={translate('tinyMedicRestApp.pharmacyBill.glucoUser')}
                type="select"
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-pharmacist"
                name="pharmacist"
                data-cy="pharmacist"
                label={translate('tinyMedicRestApp.pharmacyBill.pharmacist')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacists
                  ? pharmacists.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-admission"
                name="admission"
                data-cy="admission"
                label={translate('tinyMedicRestApp.pharmacyBill.admission')}
                type="select"
              >
                <option value="" key="0" />
                {admissions
                  ? admissions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-billPriscription"
                name="billPriscription"
                data-cy="billPriscription"
                label={translate('tinyMedicRestApp.pharmacyBill.billPriscription')}
                type="select"
              >
                <option value="" key="0" />
                {prescriptions
                  ? prescriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-scheme"
                name="scheme"
                data-cy="scheme"
                label={translate('tinyMedicRestApp.pharmacyBill.scheme')}
                type="select"
              >
                <option value="" key="0" />
                {paymentSchemes
                  ? paymentSchemes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-patientCategory"
                name="patientCategory"
                data-cy="patientCategory"
                label={translate('tinyMedicRestApp.pharmacyBill.patientCategory')}
                type="select"
              >
                <option value="" key="0" />
                {patientCategories
                  ? patientCategories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-consumer"
                name="consumer"
                data-cy="consumer"
                label={translate('tinyMedicRestApp.pharmacyBill.consumer')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacies
                  ? pharmacies.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-amendedFrom"
                name="amendedFrom"
                data-cy="amendedFrom"
                label={translate('tinyMedicRestApp.pharmacyBill.amendedFrom')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacyBills
                  ? pharmacyBills.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-amendedTo"
                name="amendedTo"
                data-cy="amendedTo"
                label={translate('tinyMedicRestApp.pharmacyBill.amendedTo')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacyBills
                  ? pharmacyBills.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-invoiceType"
                name="invoiceType"
                data-cy="invoiceType"
                label={translate('tinyMedicRestApp.pharmacyBill.invoiceType')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="pharmacy-bill-appliedCategory"
                name="appliedCategory"
                data-cy="appliedCategory"
                label={translate('tinyMedicRestApp.pharmacyBill.appliedCategory')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalPatientAttributes
                  ? hospitalPatientAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/pharmacy-bill" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PharmacyBillUpdate;
