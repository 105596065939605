import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Appointments from './appointments';
import AppointmentsDetail from './appointments-detail';
import AppointmentsUpdate from './appointments-update';
import AppointmentsDeleteDialog from './appointments-delete-dialog';

const AppointmentsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Appointments />} />
    <Route path="new" element={<AppointmentsUpdate />} />
    <Route path=":id">
      <Route index element={<AppointmentsDetail />} />
      <Route path="edit" element={<AppointmentsUpdate />} />
      <Route path="delete" element={<AppointmentsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AppointmentsRoutes;
