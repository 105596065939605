import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './appointments.reducer';

export const AppointmentsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const appointmentsEntity = useAppSelector(state => state.appointments.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="appointmentsDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.appointments.detail.title">Appointments</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.appointments.id">Id</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.id}</dd>
          <dt>
            <span id="appointmentDate">
              <Translate contentKey="tinyMedicRestApp.appointments.appointmentDate">Appointment Date</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.appointmentDate}</dd>
          <dt>
            <span id="phoneNo">
              <Translate contentKey="tinyMedicRestApp.appointments.phoneNo">Phone No</Translate>
            </span>
            <UncontrolledTooltip target="phoneNo">
              <Translate contentKey="tinyMedicRestApp.appointments.help.phoneNo" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appointmentsEntity.phoneNo}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="tinyMedicRestApp.appointments.email">Email</Translate>
            </span>
            <UncontrolledTooltip target="email">
              <Translate contentKey="tinyMedicRestApp.appointments.help.email" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appointmentsEntity.email}</dd>
          <dt>
            <span id="appointmentTypeCode">
              <Translate contentKey="tinyMedicRestApp.appointments.appointmentTypeCode">Appointment Type Code</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.appointmentTypeCode}</dd>
          <dt>
            <span id="apptStartTime">
              <Translate contentKey="tinyMedicRestApp.appointments.apptStartTime">Appt Start Time</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.apptStartTime}</dd>
          <dt>
            <span id="apptEndTime">
              <Translate contentKey="tinyMedicRestApp.appointments.apptEndTime">Appt End Time</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.apptEndTime}</dd>
          <dt>
            <span id="appointmentAgenda">
              <Translate contentKey="tinyMedicRestApp.appointments.appointmentAgenda">Appointment Agenda</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.appointmentAgenda}</dd>
          <dt>
            <span id="consultationCharge">
              <Translate contentKey="tinyMedicRestApp.appointments.consultationCharge">Consultation Charge</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.consultationCharge}</dd>
          <dt>
            <span id="primaryAppointmentNumber">
              <Translate contentKey="tinyMedicRestApp.appointments.primaryAppointmentNumber">Primary Appointment Number</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.primaryAppointmentNumber}</dd>
          <dt>
            <span id="capturedDtm">
              <Translate contentKey="tinyMedicRestApp.appointments.capturedDtm">Captured Dtm</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.capturedDtm}</dd>
          <dt>
            <span id="appointmentRemarks">
              <Translate contentKey="tinyMedicRestApp.appointments.appointmentRemarks">Appointment Remarks</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.appointmentRemarks}</dd>
          <dt>
            <span id="nextVisitDt">
              <Translate contentKey="tinyMedicRestApp.appointments.nextVisitDt">Next Visit Dt</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.nextVisitDt}</dd>
          <dt>
            <span id="createDtm">
              <Translate contentKey="tinyMedicRestApp.appointments.createDtm">Create Dtm</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.createDtm}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="tinyMedicRestApp.appointments.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.createdBy}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="tinyMedicRestApp.appointments.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.modifiedBy}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.appointments.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.timestampentry}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.appointments.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.microSec}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.appointments.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.lastupdated}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.appointments.active">Active</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.appointments.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.cursor}</dd>
          <dt>
            <span id="createdByType">
              <Translate contentKey="tinyMedicRestApp.appointments.createdByType">Created By Type</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.createdByType}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="tinyMedicRestApp.appointments.discount">Discount</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.discount}</dd>
          <dt>
            <span id="discountType">
              <Translate contentKey="tinyMedicRestApp.appointments.discountType">Discount Type</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.discountType}</dd>
          <dt>
            <span id="patientType">
              <Translate contentKey="tinyMedicRestApp.appointments.patientType">Patient Type</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.patientType}</dd>
          <dt>
            <span id="exposed">
              <Translate contentKey="tinyMedicRestApp.appointments.exposed">Exposed</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.exposed ? 'true' : 'false'}</dd>
          <dt>
            <span id="referedByName">
              <Translate contentKey="tinyMedicRestApp.appointments.referedByName">Refered By Name</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.referedByName}</dd>
          <dt>
            <span id="referedByTown">
              <Translate contentKey="tinyMedicRestApp.appointments.referedByTown">Refered By Town</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.referedByTown}</dd>
          <dt>
            <span id="paymentMethod">
              <Translate contentKey="tinyMedicRestApp.appointments.paymentMethod">Payment Method</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.paymentMethod}</dd>
          <dt>
            <span id="paymentStatus">
              <Translate contentKey="tinyMedicRestApp.appointments.paymentStatus">Payment Status</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.paymentStatus}</dd>
          <dt>
            <span id="transactionRef">
              <Translate contentKey="tinyMedicRestApp.appointments.transactionRef">Transaction Ref</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.transactionRef}</dd>
          <dt>
            <span id="emergency">
              <Translate contentKey="tinyMedicRestApp.appointments.emergency">Emergency</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.emergency ? 'true' : 'false'}</dd>
          <dt>
            <span id="highRisk">
              <Translate contentKey="tinyMedicRestApp.appointments.highRisk">High Risk</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.highRisk ? 'true' : 'false'}</dd>
          <dt>
            <span id="isMedicoLegal">
              <Translate contentKey="tinyMedicRestApp.appointments.isMedicoLegal">Is Medico Legal</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.isMedicoLegal ? 'true' : 'false'}</dd>
          <dt>
            <span id="consultType">
              <Translate contentKey="tinyMedicRestApp.appointments.consultType">Consult Type</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.consultType}</dd>
          <dt>
            <span id="checkinTime">
              <Translate contentKey="tinyMedicRestApp.appointments.checkinTime">Checkin Time</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.checkinTime}</dd>
          <dt>
            <span id="printed">
              <Translate contentKey="tinyMedicRestApp.appointments.printed">Printed</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.printed ? 'true' : 'false'}</dd>
          <dt>
            <span id="printCount">
              <Translate contentKey="tinyMedicRestApp.appointments.printCount">Print Count</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.printCount}</dd>
          <dt>
            <span id="appliedCategoryCode">
              <Translate contentKey="tinyMedicRestApp.appointments.appliedCategoryCode">Applied Category Code</Translate>
            </span>
          </dt>
          <dd>{appointmentsEntity.appliedCategoryCode}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.scheme">Scheme</Translate>
          </dt>
          <dd>{appointmentsEntity.scheme ? appointmentsEntity.scheme.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.admission">Admission</Translate>
          </dt>
          <dd>{appointmentsEntity.admission ? appointmentsEntity.admission.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.bookingTypeCode">Booking Type Code</Translate>
          </dt>
          <dd>{appointmentsEntity.bookingTypeCode ? appointmentsEntity.bookingTypeCode.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.especialtyCode">Especialty Code</Translate>
          </dt>
          <dd>{appointmentsEntity.especialtyCode ? appointmentsEntity.especialtyCode.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.doctorId">Doctor Id</Translate>
          </dt>
          <dd>{appointmentsEntity.doctorId ? appointmentsEntity.doctorId.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.patientId">Patient Id</Translate>
          </dt>
          <dd>{appointmentsEntity.patientId ? appointmentsEntity.patientId.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.referralCode">Referral Code</Translate>
          </dt>
          <dd>{appointmentsEntity.referralCode ? appointmentsEntity.referralCode.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.appointmentStatusCode">Appointment Status Code</Translate>
          </dt>
          <dd>{appointmentsEntity.appointmentStatusCode ? appointmentsEntity.appointmentStatusCode.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.doctorCharge">Doctor Charge</Translate>
          </dt>
          <dd>{appointmentsEntity.doctorCharge ? appointmentsEntity.doctorCharge.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.subscriber">Subscriber</Translate>
          </dt>
          <dd>{appointmentsEntity.subscriber ? appointmentsEntity.subscriber.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.claim">Claim</Translate>
          </dt>
          <dd>{appointmentsEntity.claim ? appointmentsEntity.claim.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.appointments.appliedCategory">Applied Category</Translate>
          </dt>
          <dd>{appointmentsEntity.appliedCategory ? appointmentsEntity.appliedCategory.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/appointments" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/appointments/${appointmentsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AppointmentsDetail;
