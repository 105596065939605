import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './admission.reducer';

export const AdmissionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const admissionEntity = useAppSelector(state => state.admission.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="admissionDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.admission.detail.title">Admission</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.admission.id">Id</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.id}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.admission.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.microSec}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.admission.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.timestampentry}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.admission.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.lastupdated}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.admission.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.cursor}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.admission.active">Active</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="advancePayment">
              <Translate contentKey="tinyMedicRestApp.admission.advancePayment">Advance Payment</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.advancePayment}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="tinyMedicRestApp.admission.status">Status</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.status}</dd>
          <dt>
            <span id="admissionStart">
              <Translate contentKey="tinyMedicRestApp.admission.admissionStart">Admission Start</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.admissionStart}</dd>
          <dt>
            <span id="admissionEnd">
              <Translate contentKey="tinyMedicRestApp.admission.admissionEnd">Admission End</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.admissionEnd}</dd>
          <dt>
            <span id="admissionNotes">
              <Translate contentKey="tinyMedicRestApp.admission.admissionNotes">Admission Notes</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.admissionNotes}</dd>
          <dt>
            <span id="snomedConceptId">
              <Translate contentKey="tinyMedicRestApp.admission.snomedConceptId">Snomed Concept Id</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.snomedConceptId}</dd>
          <dt>
            <span id="snomedConceptText">
              <Translate contentKey="tinyMedicRestApp.admission.snomedConceptText">Snomed Concept Text</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.snomedConceptText}</dd>
          <dt>
            <span id="fatherName">
              <Translate contentKey="tinyMedicRestApp.admission.fatherName">Father Name</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.fatherName}</dd>
          <dt>
            <span id="ipNumber">
              <Translate contentKey="tinyMedicRestApp.admission.ipNumber">Ip Number</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.ipNumber}</dd>
          <dt>
            <span id="emergency">
              <Translate contentKey="tinyMedicRestApp.admission.emergency">Emergency</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.emergency ? 'true' : 'false'}</dd>
          <dt>
            <span id="highRisk">
              <Translate contentKey="tinyMedicRestApp.admission.highRisk">High Risk</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.highRisk ? 'true' : 'false'}</dd>
          <dt>
            <span id="emergencyStartDate">
              <Translate contentKey="tinyMedicRestApp.admission.emergencyStartDate">Emergency Start Date</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.emergencyStartDate}</dd>
          <dt>
            <span id="highRiskStartDate">
              <Translate contentKey="tinyMedicRestApp.admission.highRiskStartDate">High Risk Start Date</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.highRiskStartDate}</dd>
          <dt>
            <span id="emergencyEndDate">
              <Translate contentKey="tinyMedicRestApp.admission.emergencyEndDate">Emergency End Date</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.emergencyEndDate}</dd>
          <dt>
            <span id="highRiskEndDate">
              <Translate contentKey="tinyMedicRestApp.admission.highRiskEndDate">High Risk End Date</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.highRiskEndDate}</dd>
          <dt>
            <span id="schemeSubscriptionId">
              <Translate contentKey="tinyMedicRestApp.admission.schemeSubscriptionId">Scheme Subscription Id</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.schemeSubscriptionId}</dd>
          <dt>
            <span id="isMedicoLegal">
              <Translate contentKey="tinyMedicRestApp.admission.isMedicoLegal">Is Medico Legal</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.isMedicoLegal ? 'true' : 'false'}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="tinyMedicRestApp.admission.type">Type</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.type}</dd>
          <dt>
            <span id="arrivalMode">
              <Translate contentKey="tinyMedicRestApp.admission.arrivalMode">Arrival Mode</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.arrivalMode}</dd>
          <dt>
            <span id="emAdmissionId">
              <Translate contentKey="tinyMedicRestApp.admission.emAdmissionId">Em Admission Id</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.emAdmissionId}</dd>
          <dt>
            <span id="appliedCategoryCode">
              <Translate contentKey="tinyMedicRestApp.admission.appliedCategoryCode">Applied Category Code</Translate>
            </span>
          </dt>
          <dd>{admissionEntity.appliedCategoryCode}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.assessment">Assessment</Translate>
          </dt>
          <dd>{admissionEntity.assessment ? admissionEntity.assessment.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.primaryConsultant">Primary Consultant</Translate>
          </dt>
          <dd>{admissionEntity.primaryConsultant ? admissionEntity.primaryConsultant.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.patient">Patient</Translate>
          </dt>
          <dd>{admissionEntity.patient ? admissionEntity.patient.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.hospital">Hospital</Translate>
          </dt>
          <dd>{admissionEntity.hospital ? admissionEntity.hospital.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.advice">Advice</Translate>
          </dt>
          <dd>{admissionEntity.advice ? admissionEntity.advice.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.address">Address</Translate>
          </dt>
          <dd>{admissionEntity.address ? admissionEntity.address.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.scheme">Scheme</Translate>
          </dt>
          <dd>{admissionEntity.scheme ? admissionEntity.scheme.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.especialtyCode">Especialty Code</Translate>
          </dt>
          <dd>{admissionEntity.especialtyCode ? admissionEntity.especialtyCode.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admission.appliedCategory">Applied Category</Translate>
          </dt>
          <dd>{admissionEntity.appliedCategory ? admissionEntity.appliedCategory.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/admission" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/admission/${admissionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AdmissionDetail;
