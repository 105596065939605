export enum DiscountType {
  NONE = 'N/A',

  CASHLESS = 'Cashless Card',

  EHS = 'EHS',

  INSURANCE = 'Insurance',

  LOYALTY = 'Loyal Patient',

  MISC = 'Miscellaneous',

  SENIOR = 'Senior Citizen',

  UNDERPREV = 'Underprivileged',

  WOMEN = 'Women',
}
