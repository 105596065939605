import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HealthCampRegistration from './health-camp-registration';
import HealthCampRegistrationDetail from './health-camp-registration-detail';
import HealthCampRegistrationUpdate from './health-camp-registration-update';
import HealthCampRegistrationDeleteDialog from './health-camp-registration-delete-dialog';

const HealthCampRegistrationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HealthCampRegistration />} />
    <Route path="new" element={<HealthCampRegistrationUpdate />} />
    <Route path=":id">
      <Route index element={<HealthCampRegistrationDetail />} />
      <Route path="edit" element={<HealthCampRegistrationUpdate />} />
      <Route path="delete" element={<HealthCampRegistrationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HealthCampRegistrationRoutes;
