export enum EntityType {
  LAB = 'LAB',

  PHARMACY = 'PHARMACY',

  HOSPITAL = 'HOSPITAL',

  DOCTOR = 'DOCTOR',

  PARAMEDIC = 'PARAMEDIC',

  BLOOD_BANK = 'BLOOD_BANK',
}
