import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './hospital-bill.reducer';

export const HospitalBillDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const hospitalBillEntity = useAppSelector(state => state.hospitalBill.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="hospitalBillDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.hospitalBill.detail.title">HospitalBill</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.id">Id</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.id}</dd>
          <dt>
            <span id="margin">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.margin">Margin</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.margin}</dd>
          <dt>
            <span id="total">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.total">Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.total}</dd>
          <dt>
            <span id="netTotal">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.netTotal">Net Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.netTotal}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.discount">Discount</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.discount}</dd>
          <dt>
            <span id="tax">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.tax">Tax</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.tax}</dd>
          <dt>
            <span id="taxPlusNetTotal">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.taxPlusNetTotal">Tax Plus Net Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.taxPlusNetTotal}</dd>
          <dt>
            <span id="discountPercent">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.discountPercent">Discount Percent</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.discountPercent}</dd>
          <dt>
            <span id="billTotal">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.billTotal">Bill Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.billTotal}</dd>
          <dt>
            <span id="paidAmount">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.paidAmount">Paid Amount</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.paidAmount}</dd>
          <dt>
            <span id="balance">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.balance">Balance</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.balance}</dd>
          <dt>
            <span id="serviceCharge">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.serviceCharge">Service Charge</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.serviceCharge}</dd>
          <dt>
            <span id="grantTotal">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.grantTotal">Grant Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.grantTotal}</dd>
          <dt>
            <span id="grnNetTotal">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.grnNetTotal">Grn Net Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.grnNetTotal}</dd>
          <dt>
            <span id="claimableTotal">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.claimableTotal">Claimable Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.claimableTotal}</dd>
          <dt>
            <span id="comments">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.comments">Comments</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.comments}</dd>
          <dt>
            <span id="cancelled">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.cancelled">Cancelled</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.cancelled ? 'true' : 'false'}</dd>
          <dt>
            <span id="refunded">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.refunded">Refunded</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.refunded ? 'true' : 'false'}</dd>
          <dt>
            <span id="reactivated">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.reactivated">Reactivated</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.reactivated ? 'true' : 'false'}</dd>
          <dt>
            <span id="billDate">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.billDate">Bill Date</Translate>
            </span>
          </dt>
          <dd>
            {hospitalBillEntity.billDate ? <TextFormat value={hospitalBillEntity.billDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="billTime">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.billTime">Bill Time</Translate>
            </span>
          </dt>
          <dd>
            {hospitalBillEntity.billTime ? <TextFormat value={hospitalBillEntity.billTime} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="billOrigin">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.billOrigin">Bill Origin</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.billOrigin}</dd>
          <dt>
            <span id="billClassType">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.billClassType">Bill Class Type</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.billClassType}</dd>
          <dt>
            <span id="printed">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.printed">Printed</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.printed ? 'true' : 'false'}</dd>
          <dt>
            <span id="insurancePolicyNo">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.insurancePolicyNo">Insurance Policy No</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.insurancePolicyNo}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.active">Active</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>
            {hospitalBillEntity.microSec ? <TextFormat value={hospitalBillEntity.microSec} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>
            {hospitalBillEntity.timestampEntry ? (
              <TextFormat value={hospitalBillEntity.timestampEntry} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>
            {hospitalBillEntity.lastUpdated ? (
              <TextFormat value={hospitalBillEntity.lastUpdated} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.cursor}</dd>
          <dt>
            <span id="billNumber">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.billNumber">Bill Number</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.billNumber}</dd>
          <dt>
            <span id="addedByType">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.addedByType">Added By Type</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.addedByType}</dd>
          <dt>
            <span id="addedByID">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.addedByID">Added By ID</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.addedByID}</dd>
          <dt>
            <span id="modifiedByType">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.modifiedByType">Modified By Type</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.modifiedByType}</dd>
          <dt>
            <span id="modifiedByID">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.modifiedByID">Modified By ID</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.modifiedByID}</dd>
          <dt>
            <span id="multiplier">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.multiplier">Multiplier</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.multiplier}</dd>
          <dt>
            <span id="roundOffTotal">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.roundOffTotal">Round Off Total</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.roundOffTotal}</dd>
          <dt>
            <span id="printCount">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.printCount">Print Count</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.printCount}</dd>
          <dt>
            <span id="voided">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.voided">Voided</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.voided ? 'true' : 'false'}</dd>
          <dt>
            <span id="voidedReason">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.voidedReason">Voided Reason</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.voidedReason}</dd>
          <dt>
            <span id="voidedDate">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.voidedDate">Voided Date</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.voidedDate}</dd>
          <dt>
            <span id="appliedCategoryCode">
              <Translate contentKey="tinyMedicRestApp.hospitalBill.appliedCategoryCode">Applied Category Code</Translate>
            </span>
          </dt>
          <dd>{hospitalBillEntity.appliedCategoryCode}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalBill.hospital">Hospital</Translate>
          </dt>
          <dd>{hospitalBillEntity.hospital ? hospitalBillEntity.hospital.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalBill.processedBy">Processed By</Translate>
          </dt>
          <dd>{hospitalBillEntity.processedBy ? hospitalBillEntity.processedBy.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalBill.admission">Admission</Translate>
          </dt>
          <dd>{hospitalBillEntity.admission ? hospitalBillEntity.admission.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalBill.scheme">Scheme</Translate>
          </dt>
          <dd>{hospitalBillEntity.scheme ? hospitalBillEntity.scheme.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalBill.patientCategory">Patient Category</Translate>
          </dt>
          <dd>{hospitalBillEntity.patientCategory ? hospitalBillEntity.patientCategory.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalBill.patient">Patient</Translate>
          </dt>
          <dd>{hospitalBillEntity.patient ? hospitalBillEntity.patient.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalBill.appliedCategory">Applied Category</Translate>
          </dt>
          <dd>{hospitalBillEntity.appliedCategory ? hospitalBillEntity.appliedCategory.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/hospital-bill" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/hospital-bill/${hospitalBillEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default HospitalBillDetail;
