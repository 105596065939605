import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './volunteer-visits.reducer';

export const VolunteerVisitsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const volunteerVisitsEntity = useAppSelector(state => state.volunteerVisits.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="volunteerVisitsDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.volunteerVisits.detail.title">VolunteerVisits</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.id">Id</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.active">Active</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.cursor}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.lastupdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.microSec}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.timestampentry}</dd>
          <dt>
            <span id="visitNotes">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.visitNotes">Visit Notes</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.visitNotes}</dd>
          <dt>
            <span id="capturedDtm">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.capturedDtm">Captured Dtm</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.capturedDtm}</dd>
          <dt>
            <span id="visitDate">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.visitDate">Visit Date</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.visitDate}</dd>
          <dt>
            <span id="paidAmount">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.paidAmount">Paid Amount</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.paidAmount}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.discount">Discount</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.discount}</dd>
          <dt>
            <span id="discountType">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.discountType">Discount Type</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.discountType}</dd>
          <dt>
            <span id="paymentMethod">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.paymentMethod">Payment Method</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.paymentMethod}</dd>
          <dt>
            <span id="paymentStatus">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.paymentStatus">Payment Status</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.paymentStatus}</dd>
          <dt>
            <span id="transactionRef">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.transactionRef">Transaction Ref</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.transactionRef}</dd>
          <dt>
            <span id="appliedCategoryCode">
              <Translate contentKey="tinyMedicRestApp.volunteerVisits.appliedCategoryCode">Applied Category Code</Translate>
            </span>
          </dt>
          <dd>{volunteerVisitsEntity.appliedCategoryCode}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.volunteerVisits.glucoUser">Gluco User</Translate>
          </dt>
          <dd>{volunteerVisitsEntity.glucoUser ? volunteerVisitsEntity.glucoUser.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.volunteerVisits.paramedic">Paramedic</Translate>
          </dt>
          <dd>{volunteerVisitsEntity.paramedic ? volunteerVisitsEntity.paramedic.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.volunteerVisits.appointmentStatus">Appointment Status</Translate>
          </dt>
          <dd>{volunteerVisitsEntity.appointmentStatus ? volunteerVisitsEntity.appointmentStatus.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.volunteerVisits.appliedCategory">Applied Category</Translate>
          </dt>
          <dd>{volunteerVisitsEntity.appliedCategory ? volunteerVisitsEntity.appliedCategory.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/volunteer-visits" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/volunteer-visits/${volunteerVisitsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VolunteerVisitsDetail;
