import { IPressure } from 'app/shared/model/pressure.model';
import { IOtherVitals } from 'app/shared/model/other-vitals.model';
import { IDoctor } from 'app/shared/model/doctor.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IPrescription } from 'app/shared/model/prescription.model';
import { IPaediatricParams } from 'app/shared/model/paediatric-params.model';
import { IPatientConditionCheckUp } from 'app/shared/model/patient-condition-check-up.model';
import { IVitals } from 'app/shared/model/vitals.model';

export interface IMedicalHistory {
  id?: string;
  tmDate?: number | null;
  history?: string | null;
  active?: boolean;
  timestampentry?: number;
  lastupdated?: number;
  cursor?: number;
  microSec?: number;
  leverFunction?: string;
  renalFunction?: string;
  diagnosisNotes?: string | null;
  assessment?: string | null;
  tmPlan?: string | null;
  prognosis?: string | null;
  pressure?: IPressure;
  otherVitals?: IOtherVitals;
  consultant?: IDoctor;
  patient?: IGlucoUser;
  prescription?: IPrescription | null;
  paediatricParams?: IPaediatricParams | null;
  patientCondition?: IPatientConditionCheckUp | null;
  vitals?: IVitals | null;
}

export const defaultValue: Readonly<IMedicalHistory> = {
  active: false,
};
