import { IPharmacy } from 'app/shared/model/pharmacy.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IPharmacist } from 'app/shared/model/pharmacist.model';
import { IAdmission } from 'app/shared/model/admission.model';
import { IPrescription } from 'app/shared/model/prescription.model';
import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { IPatientCategory } from 'app/shared/model/patient-category.model';
import { IClinicalDictionary } from 'app/shared/model/clinical-dictionary.model';
import { IHospitalPatientAttribute } from 'app/shared/model/hospital-patient-attribute.model';
import { PatientType } from 'app/shared/model/enumerations/patient-type.model';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { BillFor } from 'app/shared/model/enumerations/bill-for.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';

export interface IPharmacyBill {
  id?: string;
  billNumber?: string | null;
  billDate?: number | null;
  totalCost?: number | null;
  discount?: number | null;
  active?: boolean | null;
  cursor?: number | null;
  timestampentry?: number | null;
  microSec?: number | null;
  lastupdated?: number | null;
  cgst?: number | null;
  sgst?: number | null;
  patientType?: keyof typeof PatientType | null;
  discountType?: keyof typeof DiscountType | null;
  discountRemark?: string | null;
  verified?: boolean | null;
  referedByName?: string | null;
  referralComments?: string | null;
  billFor?: keyof typeof BillFor | null;
  orderStatus?: keyof typeof OrderStatus | null;
  roundOffVal?: number | null;
  billForId?: string | null;
  cessAmount?: number | null;
  amended?: boolean | null;
  printed?: boolean | null;
  printCount?: number | null;
  physicalReceipt?: string | null;
  discountApprovedBy?: string | null;
  voided?: boolean | null;
  voidedReason?: string | null;
  voidedDate?: number | null;
  appliedCategoryCode?: string | null;
  pharmacy?: IPharmacy | null;
  glucoUser?: IGlucoUser | null;
  pharmacist?: IPharmacist | null;
  admission?: IAdmission | null;
  billPriscription?: IPrescription | null;
  scheme?: IPaymentScheme | null;
  patientCategory?: IPatientCategory | null;
  consumer?: IPharmacy | null;
  amendedFrom?: IPharmacyBill | null;
  amendedTo?: IPharmacyBill | null;
  invoiceType?: IClinicalDictionary | null;
  appliedCategory?: IHospitalPatientAttribute | null;
}

export const defaultValue: Readonly<IPharmacyBill> = {
  active: false,
  verified: false,
  amended: false,
  printed: false,
  voided: false,
};
