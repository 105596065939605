export enum BillClassType {
  PREBILL = 'Interim Bill',

  FINAL_BILL = 'Final Bill',

  ADVANCE = 'Advance',

  BILLEDBILL = 'Billed Bill',

  CANCELLED_BILL = 'Cancelled Bill',

  REFUNDBILL = 'Refund Bill',

  OTHERBILL = 'Other Bill',

  ESTIMATION = 'Estimation',

  MISC_BILL = 'Misc Bill',
}
