import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './medical-history.reducer';

export const MedicalHistoryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const medicalHistoryEntity = useAppSelector(state => state.medicalHistory.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="medicalHistoryDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.medicalHistory.detail.title">MedicalHistory</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.id">Id</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.id}</dd>
          <dt>
            <span id="tmDate">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.tmDate">Tm Date</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.tmDate}</dd>
          <dt>
            <span id="history">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.history">History</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.history}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.active">Active</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.timestampentry}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.lastupdated}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.cursor}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.microSec}</dd>
          <dt>
            <span id="leverFunction">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.leverFunction">Lever Function</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.leverFunction}</dd>
          <dt>
            <span id="renalFunction">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.renalFunction">Renal Function</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.renalFunction}</dd>
          <dt>
            <span id="diagnosisNotes">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.diagnosisNotes">Diagnosis Notes</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.diagnosisNotes}</dd>
          <dt>
            <span id="assessment">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.assessment">Assessment</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.assessment}</dd>
          <dt>
            <span id="tmPlan">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.tmPlan">Tm Plan</Translate>
            </span>
          </dt>
          <dd>{medicalHistoryEntity.tmPlan}</dd>
          <dt>
            <span id="prognosis">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.prognosis">Prognosis</Translate>
            </span>
            <UncontrolledTooltip target="prognosis">
              <Translate contentKey="tinyMedicRestApp.medicalHistory.help.prognosis" />
            </UncontrolledTooltip>
          </dt>
          <dd>{medicalHistoryEntity.prognosis}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.pressure">Pressure</Translate>
          </dt>
          <dd>{medicalHistoryEntity.pressure ? medicalHistoryEntity.pressure.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.otherVitals">Other Vitals</Translate>
          </dt>
          <dd>{medicalHistoryEntity.otherVitals ? medicalHistoryEntity.otherVitals.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.consultant">Consultant</Translate>
          </dt>
          <dd>{medicalHistoryEntity.consultant ? medicalHistoryEntity.consultant.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.patient">Patient</Translate>
          </dt>
          <dd>{medicalHistoryEntity.patient ? medicalHistoryEntity.patient.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.prescription">Prescription</Translate>
          </dt>
          <dd>{medicalHistoryEntity.prescription ? medicalHistoryEntity.prescription.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.paediatricParams">Paediatric Params</Translate>
          </dt>
          <dd>{medicalHistoryEntity.paediatricParams ? medicalHistoryEntity.paediatricParams.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.patientCondition">Patient Condition</Translate>
          </dt>
          <dd>{medicalHistoryEntity.patientCondition ? medicalHistoryEntity.patientCondition.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.medicalHistory.vitals">Vitals</Translate>
          </dt>
          <dd>{medicalHistoryEntity.vitals ? medicalHistoryEntity.vitals.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/medical-history" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/medical-history/${medicalHistoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MedicalHistoryDetail;
