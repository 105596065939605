import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormText, Row, UncontrolledTooltip } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getPressures } from 'app/entities/pressure/pressure.reducer';
import { getEntities as getOtherVitals } from 'app/entities/other-vitals/other-vitals.reducer';
import { getEntities as getDoctors } from 'app/entities/doctor/doctor.reducer';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { getEntities as getPrescriptions } from 'app/entities/prescription/prescription.reducer';
import { getEntities as getPaediatricParams } from 'app/entities/paediatric-params/paediatric-params.reducer';
import { getEntities as getPatientConditionCheckUps } from 'app/entities/patient-condition-check-up/patient-condition-check-up.reducer';
import { getEntities as getVitals } from 'app/entities/vitals/vitals.reducer';
import { createEntity, getEntity, reset, updateEntity } from './medical-history.reducer';

export const MedicalHistoryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const pressures = useAppSelector(state => state.pressure.entities);
  const otherVitals = useAppSelector(state => state.otherVitals.entities);
  const doctors = useAppSelector(state => state.doctor.entities);
  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const prescriptions = useAppSelector(state => state.prescription.entities);
  const paediatricParams = useAppSelector(state => state.paediatricParams.entities);
  const patientConditionCheckUps = useAppSelector(state => state.patientConditionCheckUp.entities);
  const vitals = useAppSelector(state => state.vitals.entities);
  const medicalHistoryEntity = useAppSelector(state => state.medicalHistory.entity);
  const loading = useAppSelector(state => state.medicalHistory.loading);
  const updating = useAppSelector(state => state.medicalHistory.updating);
  const updateSuccess = useAppSelector(state => state.medicalHistory.updateSuccess);

  const handleClose = () => {
    navigate(`/medical-history${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPressures({}));
    dispatch(getOtherVitals({}));
    dispatch(getDoctors({}));
    dispatch(getGlucoUsers({}));
    dispatch(getPrescriptions({}));
    dispatch(getPaediatricParams({}));
    dispatch(getPatientConditionCheckUps({}));
    dispatch(getVitals({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.tmDate !== undefined && typeof values.tmDate !== 'number') {
      values.tmDate = Number(values.tmDate);
    }
    if (values.timestampentry !== undefined && typeof values.timestampentry !== 'number') {
      values.timestampentry = Number(values.timestampentry);
    }
    if (values.lastupdated !== undefined && typeof values.lastupdated !== 'number') {
      values.lastupdated = Number(values.lastupdated);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }

    const entity = {
      ...medicalHistoryEntity,
      ...values,
      pressure: pressures.find(it => it.id.toString() === values.pressure?.toString()),
      otherVitals: otherVitals.find(it => it.id.toString() === values.otherVitals?.toString()),
      consultant: doctors.find(it => it.id.toString() === values.consultant?.toString()),
      patient: glucoUsers.find(it => it.id.toString() === values.patient?.toString()),
      prescription: prescriptions.find(it => it.id.toString() === values.prescription?.toString()),
      paediatricParams: paediatricParams.find(it => it.id.toString() === values.paediatricParams?.toString()),
      patientCondition: patientConditionCheckUps.find(it => it.id.toString() === values.patientCondition?.toString()),
      vitals: vitals.find(it => it.id.toString() === values.vitals?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...medicalHistoryEntity,
          pressure: medicalHistoryEntity?.pressure?.id,
          otherVitals: medicalHistoryEntity?.otherVitals?.id,
          consultant: medicalHistoryEntity?.consultant?.id,
          patient: medicalHistoryEntity?.patient?.id,
          prescription: medicalHistoryEntity?.prescription?.id,
          paediatricParams: medicalHistoryEntity?.paediatricParams?.id,
          patientCondition: medicalHistoryEntity?.patientCondition?.id,
          vitals: medicalHistoryEntity?.vitals?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.medicalHistory.home.createOrEditLabel" data-cy="MedicalHistoryCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.medicalHistory.home.createOrEditLabel">Create or edit a MedicalHistory</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="medical-history-id"
                  label={translate('tinyMedicRestApp.medicalHistory.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.tmDate')}
                id="medical-history-tmDate"
                name="tmDate"
                data-cy="tmDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.history')}
                id="medical-history-history"
                name="history"
                data-cy="history"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.active')}
                id="medical-history-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.timestampentry')}
                id="medical-history-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.lastupdated')}
                id="medical-history-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.cursor')}
                id="medical-history-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.microSec')}
                id="medical-history-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.leverFunction')}
                id="medical-history-leverFunction"
                name="leverFunction"
                data-cy="leverFunction"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.renalFunction')}
                id="medical-history-renalFunction"
                name="renalFunction"
                data-cy="renalFunction"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.diagnosisNotes')}
                id="medical-history-diagnosisNotes"
                name="diagnosisNotes"
                data-cy="diagnosisNotes"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.assessment')}
                id="medical-history-assessment"
                name="assessment"
                data-cy="assessment"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.tmPlan')}
                id="medical-history-tmPlan"
                name="tmPlan"
                data-cy="tmPlan"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.medicalHistory.prognosis')}
                id="medical-history-prognosis"
                name="prognosis"
                data-cy="prognosis"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <UncontrolledTooltip target="prognosisLabel">
                <Translate contentKey="tinyMedicRestApp.medicalHistory.help.prognosis" />
              </UncontrolledTooltip>
              <ValidatedField
                id="medical-history-pressure"
                name="pressure"
                data-cy="pressure"
                label={translate('tinyMedicRestApp.medicalHistory.pressure')}
                type="select"
                required
              >
                <option value="" key="0" />
                {pressures
                  ? pressures.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="medical-history-otherVitals"
                name="otherVitals"
                data-cy="otherVitals"
                label={translate('tinyMedicRestApp.medicalHistory.otherVitals')}
                type="select"
                required
              >
                <option value="" key="0" />
                {otherVitals
                  ? otherVitals.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="medical-history-consultant"
                name="consultant"
                data-cy="consultant"
                label={translate('tinyMedicRestApp.medicalHistory.consultant')}
                type="select"
                required
              >
                <option value="" key="0" />
                {doctors
                  ? doctors.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="medical-history-patient"
                name="patient"
                data-cy="patient"
                label={translate('tinyMedicRestApp.medicalHistory.patient')}
                type="select"
                required
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="medical-history-prescription"
                name="prescription"
                data-cy="prescription"
                label={translate('tinyMedicRestApp.medicalHistory.prescription')}
                type="select"
              >
                <option value="" key="0" />
                {prescriptions
                  ? prescriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="medical-history-paediatricParams"
                name="paediatricParams"
                data-cy="paediatricParams"
                label={translate('tinyMedicRestApp.medicalHistory.paediatricParams')}
                type="select"
              >
                <option value="" key="0" />
                {paediatricParams
                  ? paediatricParams.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="medical-history-patientCondition"
                name="patientCondition"
                data-cy="patientCondition"
                label={translate('tinyMedicRestApp.medicalHistory.patientCondition')}
                type="select"
              >
                <option value="" key="0" />
                {patientConditionCheckUps
                  ? patientConditionCheckUps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="medical-history-vitals"
                name="vitals"
                data-cy="vitals"
                label={translate('tinyMedicRestApp.medicalHistory.vitals')}
                type="select"
              >
                <option value="" key="0" />
                {vitals
                  ? vitals.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/medical-history" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MedicalHistoryUpdate;
