import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getAdmissions } from 'app/entities/admission/admission.reducer';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { getEntities as getDiagLabs } from 'app/entities/diag-lab/diag-lab.reducer';
import { getEntities as getDiaglabAssistants } from 'app/entities/diaglab-assistant/diaglab-assistant.reducer';
import { getEntities as getPrescriptions } from 'app/entities/prescription/prescription.reducer';
import { getEntities as getLabPrescriptions } from 'app/entities/lab-prescription/lab-prescription.reducer';
import { getEntities as getPaymentSchemes } from 'app/entities/payment-scheme/payment-scheme.reducer';
import { getEntities as getPatientCategories } from 'app/entities/patient-category/patient-category.reducer';
import { getEntities as getClinicalDictionaries } from 'app/entities/clinical-dictionary/clinical-dictionary.reducer';
import { getEntities as getHospitalPatientAttributes } from 'app/entities/hospital-patient-attribute/hospital-patient-attribute.reducer';
import { PatientType } from 'app/shared/model/enumerations/patient-type.model';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { AddedByType } from 'app/shared/model/enumerations/added-by-type.model';
import { createEntity, getEntity, reset, updateEntity } from './diaglab-bill.reducer';

export const DiaglabBillUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const admissions = useAppSelector(state => state.admission.entities);
  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const diagLabs = useAppSelector(state => state.diagLab.entities);
  const diaglabAssistants = useAppSelector(state => state.diaglabAssistant.entities);
  const prescriptions = useAppSelector(state => state.prescription.entities);
  const labPrescriptions = useAppSelector(state => state.labPrescription.entities);
  const paymentSchemes = useAppSelector(state => state.paymentScheme.entities);
  const patientCategories = useAppSelector(state => state.patientCategory.entities);
  const clinicalDictionaries = useAppSelector(state => state.clinicalDictionary.entities);
  const hospitalPatientAttributes = useAppSelector(state => state.hospitalPatientAttribute.entities);
  const diaglabBillEntity = useAppSelector(state => state.diaglabBill.entity);
  const loading = useAppSelector(state => state.diaglabBill.loading);
  const updating = useAppSelector(state => state.diaglabBill.updating);
  const updateSuccess = useAppSelector(state => state.diaglabBill.updateSuccess);
  const patientTypeValues = Object.keys(PatientType);
  const discountTypeValues = Object.keys(DiscountType);
  const addedByTypeValues = Object.keys(AddedByType);

  const handleClose = () => {
    navigate(`/diaglab-bill${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getAdmissions({}));
    dispatch(getGlucoUsers({}));
    dispatch(getDiagLabs({}));
    dispatch(getDiaglabAssistants({}));
    dispatch(getPrescriptions({}));
    dispatch(getLabPrescriptions({}));
    dispatch(getPaymentSchemes({}));
    dispatch(getPatientCategories({}));
    dispatch(getClinicalDictionaries({}));
    dispatch(getHospitalPatientAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.billDate !== undefined && typeof values.billDate !== 'number') {
      values.billDate = Number(values.billDate);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastupdated !== undefined && typeof values.lastupdated !== 'number') {
      values.lastupdated = Number(values.lastupdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampentry !== undefined && typeof values.timestampentry !== 'number') {
      values.timestampentry = Number(values.timestampentry);
    }
    if (values.discount !== undefined && typeof values.discount !== 'number') {
      values.discount = Number(values.discount);
    }
    if (values.cgst !== undefined && typeof values.cgst !== 'number') {
      values.cgst = Number(values.cgst);
    }
    if (values.sgst !== undefined && typeof values.sgst !== 'number') {
      values.sgst = Number(values.sgst);
    }
    if (values.totalCost !== undefined && typeof values.totalCost !== 'number') {
      values.totalCost = Number(values.totalCost);
    }
    if (values.multiplier !== undefined && typeof values.multiplier !== 'number') {
      values.multiplier = Number(values.multiplier);
    }
    if (values.roundOffVal !== undefined && typeof values.roundOffVal !== 'number') {
      values.roundOffVal = Number(values.roundOffVal);
    }
    if (values.printCount !== undefined && typeof values.printCount !== 'number') {
      values.printCount = Number(values.printCount);
    }
    if (values.voidedDate !== undefined && typeof values.voidedDate !== 'number') {
      values.voidedDate = Number(values.voidedDate);
    }

    const entity = {
      ...diaglabBillEntity,
      ...values,
      admission: admissions.find(it => it.id.toString() === values.admission?.toString()),
      glucoUser: glucoUsers.find(it => it.id.toString() === values.glucoUser?.toString()),
      diagLab: diagLabs.find(it => it.id.toString() === values.diagLab?.toString()),
      diaglabAssistant: diaglabAssistants.find(it => it.id.toString() === values.diaglabAssistant?.toString()),
      prescription: prescriptions.find(it => it.id.toString() === values.prescription?.toString()),
      labPrescription: labPrescriptions.find(it => it.id.toString() === values.labPrescription?.toString()),
      scheme: paymentSchemes.find(it => it.id.toString() === values.scheme?.toString()),
      patientCategory: patientCategories.find(it => it.id.toString() === values.patientCategory?.toString()),
      accessEntry: clinicalDictionaries.find(it => it.id.toString() === values.accessEntry?.toString()),
      appliedCategory: hospitalPatientAttributes.find(it => it.id.toString() === values.appliedCategory?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          patientType: 'OP',
          discountType: 'NONE',
          addedByType: 'DOCTOR',
          modifiedByType: 'DOCTOR',
          ...diaglabBillEntity,
          admission: diaglabBillEntity?.admission?.id,
          glucoUser: diaglabBillEntity?.glucoUser?.id,
          diagLab: diaglabBillEntity?.diagLab?.id,
          diaglabAssistant: diaglabBillEntity?.diaglabAssistant?.id,
          prescription: diaglabBillEntity?.prescription?.id,
          labPrescription: diaglabBillEntity?.labPrescription?.id,
          scheme: diaglabBillEntity?.scheme?.id,
          patientCategory: diaglabBillEntity?.patientCategory?.id,
          accessEntry: diaglabBillEntity?.accessEntry?.id,
          appliedCategory: diaglabBillEntity?.appliedCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.diaglabBill.home.createOrEditLabel" data-cy="DiaglabBillCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.diaglabBill.home.createOrEditLabel">Create or edit a DiaglabBill</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="diaglab-bill-id"
                  label={translate('tinyMedicRestApp.diaglabBill.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.billNumber')}
                id="diaglab-bill-billNumber"
                name="billNumber"
                data-cy="billNumber"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.billDate')}
                id="diaglab-bill-billDate"
                name="billDate"
                data-cy="billDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.active')}
                id="diaglab-bill-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.cursor')}
                id="diaglab-bill-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.lastupdated')}
                id="diaglab-bill-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.microSec')}
                id="diaglab-bill-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.timestampentry')}
                id="diaglab-bill-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.discount')}
                id="diaglab-bill-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.cgst')}
                id="diaglab-bill-cgst"
                name="cgst"
                data-cy="cgst"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.sgst')}
                id="diaglab-bill-sgst"
                name="sgst"
                data-cy="sgst"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.totalCost')}
                id="diaglab-bill-totalCost"
                name="totalCost"
                data-cy="totalCost"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.statusCode')}
                id="diaglab-bill-statusCode"
                name="statusCode"
                data-cy="statusCode"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.patientType')}
                id="diaglab-bill-patientType"
                name="patientType"
                data-cy="patientType"
                type="select"
              >
                {patientTypeValues.map(patientType => (
                  <option value={patientType} key={patientType}>
                    {translate(`tinyMedicRestApp.PatientType.${patientType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.cancelledReason')}
                id="diaglab-bill-cancelledReason"
                name="cancelledReason"
                data-cy="cancelledReason"
                type="text"
                validate={{
                  maxLength: { value: 300, message: translate('entity.validation.maxlength', { max: 300 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.discountType')}
                id="diaglab-bill-discountType"
                name="discountType"
                data-cy="discountType"
                type="select"
              >
                {discountTypeValues.map(discountType => (
                  <option value={discountType} key={discountType}>
                    {translate(`tinyMedicRestApp.DiscountType.${discountType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.discountRemark')}
                id="diaglab-bill-discountRemark"
                name="discountRemark"
                data-cy="discountRemark"
                type="text"
                validate={{
                  maxLength: { value: 300, message: translate('entity.validation.maxlength', { max: 300 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.addedByType')}
                id="diaglab-bill-addedByType"
                name="addedByType"
                data-cy="addedByType"
                type="select"
              >
                {addedByTypeValues.map(addedByType => (
                  <option value={addedByType} key={addedByType}>
                    {translate(`tinyMedicRestApp.AddedByType.${addedByType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.addedByID')}
                id="diaglab-bill-addedByID"
                name="addedByID"
                data-cy="addedByID"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.modifiedByType')}
                id="diaglab-bill-modifiedByType"
                name="modifiedByType"
                data-cy="modifiedByType"
                type="select"
              >
                {addedByTypeValues.map(addedByType => (
                  <option value={addedByType} key={addedByType}>
                    {translate(`tinyMedicRestApp.AddedByType.${addedByType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.modifiedByID')}
                id="diaglab-bill-modifiedByID"
                name="modifiedByID"
                data-cy="modifiedByID"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.referedByName')}
                id="diaglab-bill-referedByName"
                name="referedByName"
                data-cy="referedByName"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.referralComments')}
                id="diaglab-bill-referralComments"
                name="referralComments"
                data-cy="referralComments"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.multiplier')}
                id="diaglab-bill-multiplier"
                name="multiplier"
                data-cy="multiplier"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.roundOffVal')}
                id="diaglab-bill-roundOffVal"
                name="roundOffVal"
                data-cy="roundOffVal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.printed')}
                id="diaglab-bill-printed"
                name="printed"
                data-cy="printed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.printCount')}
                id="diaglab-bill-printCount"
                name="printCount"
                data-cy="printCount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.voided')}
                id="diaglab-bill-voided"
                name="voided"
                data-cy="voided"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.voidedReason')}
                id="diaglab-bill-voidedReason"
                name="voidedReason"
                data-cy="voidedReason"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.voidedDate')}
                id="diaglab-bill-voidedDate"
                name="voidedDate"
                data-cy="voidedDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.diaglabBill.appliedCategoryCode')}
                id="diaglab-bill-appliedCategoryCode"
                name="appliedCategoryCode"
                data-cy="appliedCategoryCode"
                type="text"
              />
              <ValidatedField
                id="diaglab-bill-admission"
                name="admission"
                data-cy="admission"
                label={translate('tinyMedicRestApp.diaglabBill.admission')}
                type="select"
              >
                <option value="" key="0" />
                {admissions
                  ? admissions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-glucoUser"
                name="glucoUser"
                data-cy="glucoUser"
                label={translate('tinyMedicRestApp.diaglabBill.glucoUser')}
                type="select"
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-diagLab"
                name="diagLab"
                data-cy="diagLab"
                label={translate('tinyMedicRestApp.diaglabBill.diagLab')}
                type="select"
              >
                <option value="" key="0" />
                {diagLabs
                  ? diagLabs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-diaglabAssistant"
                name="diaglabAssistant"
                data-cy="diaglabAssistant"
                label={translate('tinyMedicRestApp.diaglabBill.diaglabAssistant')}
                type="select"
              >
                <option value="" key="0" />
                {diaglabAssistants
                  ? diaglabAssistants.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-prescription"
                name="prescription"
                data-cy="prescription"
                label={translate('tinyMedicRestApp.diaglabBill.prescription')}
                type="select"
              >
                <option value="" key="0" />
                {prescriptions
                  ? prescriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-labPrescription"
                name="labPrescription"
                data-cy="labPrescription"
                label={translate('tinyMedicRestApp.diaglabBill.labPrescription')}
                type="select"
              >
                <option value="" key="0" />
                {labPrescriptions
                  ? labPrescriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-scheme"
                name="scheme"
                data-cy="scheme"
                label={translate('tinyMedicRestApp.diaglabBill.scheme')}
                type="select"
              >
                <option value="" key="0" />
                {paymentSchemes
                  ? paymentSchemes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-patientCategory"
                name="patientCategory"
                data-cy="patientCategory"
                label={translate('tinyMedicRestApp.diaglabBill.patientCategory')}
                type="select"
              >
                <option value="" key="0" />
                {patientCategories
                  ? patientCategories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-accessEntry"
                name="accessEntry"
                data-cy="accessEntry"
                label={translate('tinyMedicRestApp.diaglabBill.accessEntry')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="diaglab-bill-appliedCategory"
                name="appliedCategory"
                data-cy="appliedCategory"
                label={translate('tinyMedicRestApp.diaglabBill.appliedCategory')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalPatientAttributes
                  ? hospitalPatientAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/diaglab-bill" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DiaglabBillUpdate;
