import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getHospitals } from 'app/entities/hospital/hospital.reducer';
import { getEntities as getHospitalAccountants } from 'app/entities/hospital-accountant/hospital-accountant.reducer';
import { getEntities as getAdmissions } from 'app/entities/admission/admission.reducer';
import { getEntities as getPaymentSchemes } from 'app/entities/payment-scheme/payment-scheme.reducer';
import { getEntities as getPatientCategories } from 'app/entities/patient-category/patient-category.reducer';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { getEntities as getHospitalPatientAttributes } from 'app/entities/hospital-patient-attribute/hospital-patient-attribute.reducer';
import { BillOrigin } from 'app/shared/model/enumerations/bill-origin.model';
import { BillClassType } from 'app/shared/model/enumerations/bill-class-type.model';
import { AddedByType } from 'app/shared/model/enumerations/added-by-type.model';
import { createEntity, getEntity, reset, updateEntity } from './hospital-bill.reducer';

export const HospitalBillUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const hospitals = useAppSelector(state => state.hospital.entities);
  const hospitalAccountants = useAppSelector(state => state.hospitalAccountant.entities);
  const admissions = useAppSelector(state => state.admission.entities);
  const paymentSchemes = useAppSelector(state => state.paymentScheme.entities);
  const patientCategories = useAppSelector(state => state.patientCategory.entities);
  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const hospitalPatientAttributes = useAppSelector(state => state.hospitalPatientAttribute.entities);
  const hospitalBillEntity = useAppSelector(state => state.hospitalBill.entity);
  const loading = useAppSelector(state => state.hospitalBill.loading);
  const updating = useAppSelector(state => state.hospitalBill.updating);
  const updateSuccess = useAppSelector(state => state.hospitalBill.updateSuccess);
  const billOriginValues = Object.keys(BillOrigin);
  const billClassTypeValues = Object.keys(BillClassType);
  const addedByTypeValues = Object.keys(AddedByType);

  const handleClose = () => {
    navigate(`/hospital-bill${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getHospitals({}));
    dispatch(getHospitalAccountants({}));
    dispatch(getAdmissions({}));
    dispatch(getPaymentSchemes({}));
    dispatch(getPatientCategories({}));
    dispatch(getGlucoUsers({}));
    dispatch(getHospitalPatientAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.margin !== undefined && typeof values.margin !== 'number') {
      values.margin = Number(values.margin);
    }
    if (values.total !== undefined && typeof values.total !== 'number') {
      values.total = Number(values.total);
    }
    if (values.netTotal !== undefined && typeof values.netTotal !== 'number') {
      values.netTotal = Number(values.netTotal);
    }
    if (values.discount !== undefined && typeof values.discount !== 'number') {
      values.discount = Number(values.discount);
    }
    if (values.tax !== undefined && typeof values.tax !== 'number') {
      values.tax = Number(values.tax);
    }
    if (values.taxPlusNetTotal !== undefined && typeof values.taxPlusNetTotal !== 'number') {
      values.taxPlusNetTotal = Number(values.taxPlusNetTotal);
    }
    if (values.discountPercent !== undefined && typeof values.discountPercent !== 'number') {
      values.discountPercent = Number(values.discountPercent);
    }
    if (values.billTotal !== undefined && typeof values.billTotal !== 'number') {
      values.billTotal = Number(values.billTotal);
    }
    if (values.paidAmount !== undefined && typeof values.paidAmount !== 'number') {
      values.paidAmount = Number(values.paidAmount);
    }
    if (values.balance !== undefined && typeof values.balance !== 'number') {
      values.balance = Number(values.balance);
    }
    if (values.serviceCharge !== undefined && typeof values.serviceCharge !== 'number') {
      values.serviceCharge = Number(values.serviceCharge);
    }
    if (values.grantTotal !== undefined && typeof values.grantTotal !== 'number') {
      values.grantTotal = Number(values.grantTotal);
    }
    if (values.grnNetTotal !== undefined && typeof values.grnNetTotal !== 'number') {
      values.grnNetTotal = Number(values.grnNetTotal);
    }
    if (values.claimableTotal !== undefined && typeof values.claimableTotal !== 'number') {
      values.claimableTotal = Number(values.claimableTotal);
    }
    values.billDate = convertDateTimeToServer(values.billDate);
    values.billTime = convertDateTimeToServer(values.billTime);
    values.microSec = convertDateTimeToServer(values.microSec);
    values.timestampEntry = convertDateTimeToServer(values.timestampEntry);
    values.lastUpdated = convertDateTimeToServer(values.lastUpdated);
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.multiplier !== undefined && typeof values.multiplier !== 'number') {
      values.multiplier = Number(values.multiplier);
    }
    if (values.roundOffTotal !== undefined && typeof values.roundOffTotal !== 'number') {
      values.roundOffTotal = Number(values.roundOffTotal);
    }
    if (values.printCount !== undefined && typeof values.printCount !== 'number') {
      values.printCount = Number(values.printCount);
    }
    if (values.voidedDate !== undefined && typeof values.voidedDate !== 'number') {
      values.voidedDate = Number(values.voidedDate);
    }

    const entity = {
      ...hospitalBillEntity,
      ...values,
      hospital: hospitals.find(it => it.id.toString() === values.hospital?.toString()),
      processedBy: hospitalAccountants.find(it => it.id.toString() === values.processedBy?.toString()),
      admission: admissions.find(it => it.id.toString() === values.admission?.toString()),
      scheme: paymentSchemes.find(it => it.id.toString() === values.scheme?.toString()),
      patientCategory: patientCategories.find(it => it.id.toString() === values.patientCategory?.toString()),
      patient: glucoUsers.find(it => it.id.toString() === values.patient?.toString()),
      appliedCategory: hospitalPatientAttributes.find(it => it.id.toString() === values.appliedCategory?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          billDate: displayDefaultDateTime(),
          billTime: displayDefaultDateTime(),
          microSec: displayDefaultDateTime(),
          timestampEntry: displayDefaultDateTime(),
          lastUpdated: displayDefaultDateTime(),
        }
      : {
          billOrigin: 'OP',
          billClassType: 'PREBILL',
          addedByType: 'DOCTOR',
          modifiedByType: 'DOCTOR',
          ...hospitalBillEntity,
          billDate: convertDateTimeFromServer(hospitalBillEntity.billDate),
          billTime: convertDateTimeFromServer(hospitalBillEntity.billTime),
          microSec: convertDateTimeFromServer(hospitalBillEntity.microSec),
          timestampEntry: convertDateTimeFromServer(hospitalBillEntity.timestampEntry),
          lastUpdated: convertDateTimeFromServer(hospitalBillEntity.lastUpdated),
          hospital: hospitalBillEntity?.hospital?.id,
          processedBy: hospitalBillEntity?.processedBy?.id,
          admission: hospitalBillEntity?.admission?.id,
          scheme: hospitalBillEntity?.scheme?.id,
          patientCategory: hospitalBillEntity?.patientCategory?.id,
          patient: hospitalBillEntity?.patient?.id,
          appliedCategory: hospitalBillEntity?.appliedCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.hospitalBill.home.createOrEditLabel" data-cy="HospitalBillCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.hospitalBill.home.createOrEditLabel">Create or edit a HospitalBill</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="hospital-bill-id"
                  label={translate('tinyMedicRestApp.hospitalBill.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.margin')}
                id="hospital-bill-margin"
                name="margin"
                data-cy="margin"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.total')}
                id="hospital-bill-total"
                name="total"
                data-cy="total"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.netTotal')}
                id="hospital-bill-netTotal"
                name="netTotal"
                data-cy="netTotal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.discount')}
                id="hospital-bill-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.tax')}
                id="hospital-bill-tax"
                name="tax"
                data-cy="tax"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.taxPlusNetTotal')}
                id="hospital-bill-taxPlusNetTotal"
                name="taxPlusNetTotal"
                data-cy="taxPlusNetTotal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.discountPercent')}
                id="hospital-bill-discountPercent"
                name="discountPercent"
                data-cy="discountPercent"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.billTotal')}
                id="hospital-bill-billTotal"
                name="billTotal"
                data-cy="billTotal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.paidAmount')}
                id="hospital-bill-paidAmount"
                name="paidAmount"
                data-cy="paidAmount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.balance')}
                id="hospital-bill-balance"
                name="balance"
                data-cy="balance"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.serviceCharge')}
                id="hospital-bill-serviceCharge"
                name="serviceCharge"
                data-cy="serviceCharge"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.grantTotal')}
                id="hospital-bill-grantTotal"
                name="grantTotal"
                data-cy="grantTotal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.grnNetTotal')}
                id="hospital-bill-grnNetTotal"
                name="grnNetTotal"
                data-cy="grnNetTotal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.claimableTotal')}
                id="hospital-bill-claimableTotal"
                name="claimableTotal"
                data-cy="claimableTotal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.comments')}
                id="hospital-bill-comments"
                name="comments"
                data-cy="comments"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.cancelled')}
                id="hospital-bill-cancelled"
                name="cancelled"
                data-cy="cancelled"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.refunded')}
                id="hospital-bill-refunded"
                name="refunded"
                data-cy="refunded"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.reactivated')}
                id="hospital-bill-reactivated"
                name="reactivated"
                data-cy="reactivated"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.billDate')}
                id="hospital-bill-billDate"
                name="billDate"
                data-cy="billDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.billTime')}
                id="hospital-bill-billTime"
                name="billTime"
                data-cy="billTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.billOrigin')}
                id="hospital-bill-billOrigin"
                name="billOrigin"
                data-cy="billOrigin"
                type="select"
              >
                {billOriginValues.map(billOrigin => (
                  <option value={billOrigin} key={billOrigin}>
                    {translate(`tinyMedicRestApp.BillOrigin.${billOrigin}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.billClassType')}
                id="hospital-bill-billClassType"
                name="billClassType"
                data-cy="billClassType"
                type="select"
              >
                {billClassTypeValues.map(billClassType => (
                  <option value={billClassType} key={billClassType}>
                    {translate(`tinyMedicRestApp.BillClassType.${billClassType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.printed')}
                id="hospital-bill-printed"
                name="printed"
                data-cy="printed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.insurancePolicyNo')}
                id="hospital-bill-insurancePolicyNo"
                name="insurancePolicyNo"
                data-cy="insurancePolicyNo"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.active')}
                id="hospital-bill-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.microSec')}
                id="hospital-bill-microSec"
                name="microSec"
                data-cy="microSec"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.timestampEntry')}
                id="hospital-bill-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.lastUpdated')}
                id="hospital-bill-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.cursor')}
                id="hospital-bill-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.billNumber')}
                id="hospital-bill-billNumber"
                name="billNumber"
                data-cy="billNumber"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.addedByType')}
                id="hospital-bill-addedByType"
                name="addedByType"
                data-cy="addedByType"
                type="select"
              >
                {addedByTypeValues.map(addedByType => (
                  <option value={addedByType} key={addedByType}>
                    {translate(`tinyMedicRestApp.AddedByType.${addedByType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.addedByID')}
                id="hospital-bill-addedByID"
                name="addedByID"
                data-cy="addedByID"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.modifiedByType')}
                id="hospital-bill-modifiedByType"
                name="modifiedByType"
                data-cy="modifiedByType"
                type="select"
              >
                {addedByTypeValues.map(addedByType => (
                  <option value={addedByType} key={addedByType}>
                    {translate(`tinyMedicRestApp.AddedByType.${addedByType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.modifiedByID')}
                id="hospital-bill-modifiedByID"
                name="modifiedByID"
                data-cy="modifiedByID"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.multiplier')}
                id="hospital-bill-multiplier"
                name="multiplier"
                data-cy="multiplier"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.roundOffTotal')}
                id="hospital-bill-roundOffTotal"
                name="roundOffTotal"
                data-cy="roundOffTotal"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.printCount')}
                id="hospital-bill-printCount"
                name="printCount"
                data-cy="printCount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.voided')}
                id="hospital-bill-voided"
                name="voided"
                data-cy="voided"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.voidedReason')}
                id="hospital-bill-voidedReason"
                name="voidedReason"
                data-cy="voidedReason"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.voidedDate')}
                id="hospital-bill-voidedDate"
                name="voidedDate"
                data-cy="voidedDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalBill.appliedCategoryCode')}
                id="hospital-bill-appliedCategoryCode"
                name="appliedCategoryCode"
                data-cy="appliedCategoryCode"
                type="text"
              />
              <ValidatedField
                id="hospital-bill-hospital"
                name="hospital"
                data-cy="hospital"
                label={translate('tinyMedicRestApp.hospitalBill.hospital')}
                type="select"
              >
                <option value="" key="0" />
                {hospitals
                  ? hospitals.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="hospital-bill-processedBy"
                name="processedBy"
                data-cy="processedBy"
                label={translate('tinyMedicRestApp.hospitalBill.processedBy')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalAccountants
                  ? hospitalAccountants.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="hospital-bill-admission"
                name="admission"
                data-cy="admission"
                label={translate('tinyMedicRestApp.hospitalBill.admission')}
                type="select"
              >
                <option value="" key="0" />
                {admissions
                  ? admissions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="hospital-bill-scheme"
                name="scheme"
                data-cy="scheme"
                label={translate('tinyMedicRestApp.hospitalBill.scheme')}
                type="select"
              >
                <option value="" key="0" />
                {paymentSchemes
                  ? paymentSchemes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="hospital-bill-patientCategory"
                name="patientCategory"
                data-cy="patientCategory"
                label={translate('tinyMedicRestApp.hospitalBill.patientCategory')}
                type="select"
              >
                <option value="" key="0" />
                {patientCategories
                  ? patientCategories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="hospital-bill-patient"
                name="patient"
                data-cy="patient"
                label={translate('tinyMedicRestApp.hospitalBill.patient')}
                type="select"
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="hospital-bill-appliedCategory"
                name="appliedCategory"
                data-cy="appliedCategory"
                label={translate('tinyMedicRestApp.hospitalBill.appliedCategory')}
                type="select"
              >
                <option value="" key="0" />
                {hospitalPatientAttributes
                  ? hospitalPatientAttributes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/hospital-bill" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default HospitalBillUpdate;
