import { IPreAssessment } from 'app/shared/model/pre-assessment.model';
import { IDoctor } from 'app/shared/model/doctor.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IHospital } from 'app/shared/model/hospital.model';
import { IAdmissionAdvice } from 'app/shared/model/admission-advice.model';
import { IContactDetails } from 'app/shared/model/contact-details.model';
import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { IEspecialty } from 'app/shared/model/especialty.model';
import { IHospitalPatientAttribute } from 'app/shared/model/hospital-patient-attribute.model';
import { AdmissionStatus } from 'app/shared/model/enumerations/admission-status.model';
import { AdmissionType } from 'app/shared/model/enumerations/admission-type.model';
import { ArrivalMode } from 'app/shared/model/enumerations/arrival-mode.model';

export interface IAdmission {
  id?: string;
  microSec?: number | null;
  timestampentry?: number | null;
  lastupdated?: number | null;
  cursor?: number;
  active?: boolean | null;
  advancePayment?: number | null;
  status?: keyof typeof AdmissionStatus | null;
  admissionStart?: number | null;
  admissionEnd?: number | null;
  admissionNotes?: string | null;
  snomedConceptId?: string;
  snomedConceptText?: string;
  fatherName?: string | null;
  ipNumber?: string | null;
  emergency?: boolean | null;
  highRisk?: boolean | null;
  emergencyStartDate?: number | null;
  highRiskStartDate?: number | null;
  emergencyEndDate?: number | null;
  highRiskEndDate?: number | null;
  schemeSubscriptionId?: string | null;
  isMedicoLegal?: boolean;
  type?: keyof typeof AdmissionType | null;
  arrivalMode?: keyof typeof ArrivalMode | null;
  emAdmissionId?: string | null;
  appliedCategoryCode?: string | null;
  assessment?: IPreAssessment | null;
  primaryConsultant?: IDoctor;
  patient?: IGlucoUser;
  hospital?: IHospital;
  advice?: IAdmissionAdvice | null;
  address?: IContactDetails | null;
  scheme?: IPaymentScheme | null;
  especialtyCode?: IEspecialty;
  appliedCategory?: IHospitalPatientAttribute | null;
}

export const defaultValue: Readonly<IAdmission> = {
  active: false,
  emergency: false,
  highRisk: false,
  isMedicoLegal: false,
};
