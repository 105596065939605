import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './diaglab-bill.reducer';

export const DiaglabBillDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const diaglabBillEntity = useAppSelector(state => state.diaglabBill.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="diaglabBillDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.diaglabBill.detail.title">DiaglabBill</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.id">Id</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.id}</dd>
          <dt>
            <span id="billNumber">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.billNumber">Bill Number</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.billNumber}</dd>
          <dt>
            <span id="billDate">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.billDate">Bill Date</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.billDate}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.active">Active</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.cursor}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.lastupdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.microSec}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.timestampentry}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.discount">Discount</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.discount}</dd>
          <dt>
            <span id="cgst">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.cgst">Cgst</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.cgst}</dd>
          <dt>
            <span id="sgst">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.sgst">Sgst</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.sgst}</dd>
          <dt>
            <span id="totalCost">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.totalCost">Total Cost</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.totalCost}</dd>
          <dt>
            <span id="statusCode">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.statusCode">Status Code</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.statusCode}</dd>
          <dt>
            <span id="patientType">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.patientType">Patient Type</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.patientType}</dd>
          <dt>
            <span id="cancelledReason">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.cancelledReason">Cancelled Reason</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.cancelledReason}</dd>
          <dt>
            <span id="discountType">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.discountType">Discount Type</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.discountType}</dd>
          <dt>
            <span id="discountRemark">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.discountRemark">Discount Remark</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.discountRemark}</dd>
          <dt>
            <span id="addedByType">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.addedByType">Added By Type</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.addedByType}</dd>
          <dt>
            <span id="addedByID">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.addedByID">Added By ID</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.addedByID}</dd>
          <dt>
            <span id="modifiedByType">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.modifiedByType">Modified By Type</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.modifiedByType}</dd>
          <dt>
            <span id="modifiedByID">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.modifiedByID">Modified By ID</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.modifiedByID}</dd>
          <dt>
            <span id="referedByName">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.referedByName">Refered By Name</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.referedByName}</dd>
          <dt>
            <span id="referralComments">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.referralComments">Referral Comments</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.referralComments}</dd>
          <dt>
            <span id="multiplier">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.multiplier">Multiplier</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.multiplier}</dd>
          <dt>
            <span id="roundOffVal">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.roundOffVal">Round Off Val</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.roundOffVal}</dd>
          <dt>
            <span id="printed">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.printed">Printed</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.printed ? 'true' : 'false'}</dd>
          <dt>
            <span id="printCount">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.printCount">Print Count</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.printCount}</dd>
          <dt>
            <span id="voided">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.voided">Voided</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.voided ? 'true' : 'false'}</dd>
          <dt>
            <span id="voidedReason">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.voidedReason">Voided Reason</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.voidedReason}</dd>
          <dt>
            <span id="voidedDate">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.voidedDate">Voided Date</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.voidedDate}</dd>
          <dt>
            <span id="appliedCategoryCode">
              <Translate contentKey="tinyMedicRestApp.diaglabBill.appliedCategoryCode">Applied Category Code</Translate>
            </span>
          </dt>
          <dd>{diaglabBillEntity.appliedCategoryCode}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.admission">Admission</Translate>
          </dt>
          <dd>{diaglabBillEntity.admission ? diaglabBillEntity.admission.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.glucoUser">Gluco User</Translate>
          </dt>
          <dd>{diaglabBillEntity.glucoUser ? diaglabBillEntity.glucoUser.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.diagLab">Diag Lab</Translate>
          </dt>
          <dd>{diaglabBillEntity.diagLab ? diaglabBillEntity.diagLab.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.diaglabAssistant">Diaglab Assistant</Translate>
          </dt>
          <dd>{diaglabBillEntity.diaglabAssistant ? diaglabBillEntity.diaglabAssistant.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.prescription">Prescription</Translate>
          </dt>
          <dd>{diaglabBillEntity.prescription ? diaglabBillEntity.prescription.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.labPrescription">Lab Prescription</Translate>
          </dt>
          <dd>{diaglabBillEntity.labPrescription ? diaglabBillEntity.labPrescription.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.scheme">Scheme</Translate>
          </dt>
          <dd>{diaglabBillEntity.scheme ? diaglabBillEntity.scheme.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.patientCategory">Patient Category</Translate>
          </dt>
          <dd>{diaglabBillEntity.patientCategory ? diaglabBillEntity.patientCategory.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.accessEntry">Access Entry</Translate>
          </dt>
          <dd>{diaglabBillEntity.accessEntry ? diaglabBillEntity.accessEntry.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diaglabBill.appliedCategory">Applied Category</Translate>
          </dt>
          <dd>{diaglabBillEntity.appliedCategory ? diaglabBillEntity.appliedCategory.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/diaglab-bill" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/diaglab-bill/${diaglabBillEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DiaglabBillDetail;
