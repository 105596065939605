import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, Row, Table } from 'reactstrap';
import { JhiItemCount, JhiPagination, Translate, getPaginationState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, searchEntities } from './admission.reducer';

export const Admission = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const admissionList = useAppSelector(state => state.admission.entities);
  const loading = useAppSelector(state => state.admission.loading);
  const totalItems = useAppSelector(state => state.admission.totalItems);

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        }),
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    }
    return order === ASC ? faSortUp : faSortDown;
  };

  return (
    <div>
      <h2 id="admission-heading" data-cy="AdmissionHeading">
        <Translate contentKey="tinyMedicRestApp.admission.home.title">Admissions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="tinyMedicRestApp.admission.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/admission/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="tinyMedicRestApp.admission.home.createLabel">Create new Admission</Translate>
          </Link>
        </div>
      </h2>
      <Row>
        <Col sm="12">
          <Form onSubmit={startSearching}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  defaultValue={search}
                  onChange={handleSearch}
                  placeholder={translate('tinyMedicRestApp.admission.home.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <div className="table-responsive">
        {admissionList && admissionList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="tinyMedicRestApp.admission.id">Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('microSec')}>
                  <Translate contentKey="tinyMedicRestApp.admission.microSec">Micro Sec</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('microSec')} />
                </th>
                <th className="hand" onClick={sort('timestampentry')}>
                  <Translate contentKey="tinyMedicRestApp.admission.timestampentry">Timestampentry</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('timestampentry')} />
                </th>
                <th className="hand" onClick={sort('lastupdated')}>
                  <Translate contentKey="tinyMedicRestApp.admission.lastupdated">Lastupdated</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastupdated')} />
                </th>
                <th className="hand" onClick={sort('cursor')}>
                  <Translate contentKey="tinyMedicRestApp.admission.cursor">Cursor</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('cursor')} />
                </th>
                <th className="hand" onClick={sort('active')}>
                  <Translate contentKey="tinyMedicRestApp.admission.active">Active</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('active')} />
                </th>
                <th className="hand" onClick={sort('advancePayment')}>
                  <Translate contentKey="tinyMedicRestApp.admission.advancePayment">Advance Payment</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('advancePayment')} />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="tinyMedicRestApp.admission.status">Status</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('status')} />
                </th>
                <th className="hand" onClick={sort('admissionStart')}>
                  <Translate contentKey="tinyMedicRestApp.admission.admissionStart">Admission Start</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('admissionStart')} />
                </th>
                <th className="hand" onClick={sort('admissionEnd')}>
                  <Translate contentKey="tinyMedicRestApp.admission.admissionEnd">Admission End</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('admissionEnd')} />
                </th>
                <th className="hand" onClick={sort('admissionNotes')}>
                  <Translate contentKey="tinyMedicRestApp.admission.admissionNotes">Admission Notes</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('admissionNotes')} />
                </th>
                <th className="hand" onClick={sort('snomedConceptId')}>
                  <Translate contentKey="tinyMedicRestApp.admission.snomedConceptId">Snomed Concept Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('snomedConceptId')} />
                </th>
                <th className="hand" onClick={sort('snomedConceptText')}>
                  <Translate contentKey="tinyMedicRestApp.admission.snomedConceptText">Snomed Concept Text</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('snomedConceptText')} />
                </th>
                <th className="hand" onClick={sort('fatherName')}>
                  <Translate contentKey="tinyMedicRestApp.admission.fatherName">Father Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('fatherName')} />
                </th>
                <th className="hand" onClick={sort('ipNumber')}>
                  <Translate contentKey="tinyMedicRestApp.admission.ipNumber">Ip Number</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('ipNumber')} />
                </th>
                <th className="hand" onClick={sort('emergency')}>
                  <Translate contentKey="tinyMedicRestApp.admission.emergency">Emergency</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('emergency')} />
                </th>
                <th className="hand" onClick={sort('highRisk')}>
                  <Translate contentKey="tinyMedicRestApp.admission.highRisk">High Risk</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('highRisk')} />
                </th>
                <th className="hand" onClick={sort('emergencyStartDate')}>
                  <Translate contentKey="tinyMedicRestApp.admission.emergencyStartDate">Emergency Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('emergencyStartDate')} />
                </th>
                <th className="hand" onClick={sort('highRiskStartDate')}>
                  <Translate contentKey="tinyMedicRestApp.admission.highRiskStartDate">High Risk Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('highRiskStartDate')} />
                </th>
                <th className="hand" onClick={sort('emergencyEndDate')}>
                  <Translate contentKey="tinyMedicRestApp.admission.emergencyEndDate">Emergency End Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('emergencyEndDate')} />
                </th>
                <th className="hand" onClick={sort('highRiskEndDate')}>
                  <Translate contentKey="tinyMedicRestApp.admission.highRiskEndDate">High Risk End Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('highRiskEndDate')} />
                </th>
                <th className="hand" onClick={sort('schemeSubscriptionId')}>
                  <Translate contentKey="tinyMedicRestApp.admission.schemeSubscriptionId">Scheme Subscription Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('schemeSubscriptionId')} />
                </th>
                <th className="hand" onClick={sort('isMedicoLegal')}>
                  <Translate contentKey="tinyMedicRestApp.admission.isMedicoLegal">Is Medico Legal</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isMedicoLegal')} />
                </th>
                <th className="hand" onClick={sort('type')}>
                  <Translate contentKey="tinyMedicRestApp.admission.type">Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('type')} />
                </th>
                <th className="hand" onClick={sort('arrivalMode')}>
                  <Translate contentKey="tinyMedicRestApp.admission.arrivalMode">Arrival Mode</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('arrivalMode')} />
                </th>
                <th className="hand" onClick={sort('emAdmissionId')}>
                  <Translate contentKey="tinyMedicRestApp.admission.emAdmissionId">Em Admission Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('emAdmissionId')} />
                </th>
                <th className="hand" onClick={sort('appliedCategoryCode')}>
                  <Translate contentKey="tinyMedicRestApp.admission.appliedCategoryCode">Applied Category Code</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('appliedCategoryCode')} />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.assessment">Assessment</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.primaryConsultant">Primary Consultant</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.patient">Patient</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.hospital">Hospital</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.advice">Advice</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.address">Address</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.scheme">Scheme</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.especialtyCode">Especialty Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="tinyMedicRestApp.admission.appliedCategory">Applied Category</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {admissionList.map((admission, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/admission/${admission.id}`} color="link" size="sm">
                      {admission.id}
                    </Button>
                  </td>
                  <td>{admission.microSec}</td>
                  <td>{admission.timestampentry}</td>
                  <td>{admission.lastupdated}</td>
                  <td>{admission.cursor}</td>
                  <td>{admission.active ? 'true' : 'false'}</td>
                  <td>{admission.advancePayment}</td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.AdmissionStatus.${admission.status}`} />
                  </td>
                  <td>{admission.admissionStart}</td>
                  <td>{admission.admissionEnd}</td>
                  <td>{admission.admissionNotes}</td>
                  <td>{admission.snomedConceptId}</td>
                  <td>{admission.snomedConceptText}</td>
                  <td>{admission.fatherName}</td>
                  <td>{admission.ipNumber}</td>
                  <td>{admission.emergency ? 'true' : 'false'}</td>
                  <td>{admission.highRisk ? 'true' : 'false'}</td>
                  <td>{admission.emergencyStartDate}</td>
                  <td>{admission.highRiskStartDate}</td>
                  <td>{admission.emergencyEndDate}</td>
                  <td>{admission.highRiskEndDate}</td>
                  <td>{admission.schemeSubscriptionId}</td>
                  <td>{admission.isMedicoLegal ? 'true' : 'false'}</td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.AdmissionType.${admission.type}`} />
                  </td>
                  <td>
                    <Translate contentKey={`tinyMedicRestApp.ArrivalMode.${admission.arrivalMode}`} />
                  </td>
                  <td>{admission.emAdmissionId}</td>
                  <td>{admission.appliedCategoryCode}</td>
                  <td>
                    {admission.assessment ? <Link to={`/pre-assessment/${admission.assessment.id}`}>{admission.assessment.id}</Link> : ''}
                  </td>
                  <td>
                    {admission.primaryConsultant ? (
                      <Link to={`/doctor/${admission.primaryConsultant.id}`}>{admission.primaryConsultant.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{admission.patient ? <Link to={`/gluco-user/${admission.patient.id}`}>{admission.patient.id}</Link> : ''}</td>
                  <td>{admission.hospital ? <Link to={`/hospital/${admission.hospital.id}`}>{admission.hospital.id}</Link> : ''}</td>
                  <td>{admission.advice ? <Link to={`/admission-advice/${admission.advice.id}`}>{admission.advice.id}</Link> : ''}</td>
                  <td>{admission.address ? <Link to={`/contact-details/${admission.address.id}`}>{admission.address.id}</Link> : ''}</td>
                  <td>{admission.scheme ? <Link to={`/payment-scheme/${admission.scheme.id}`}>{admission.scheme.id}</Link> : ''}</td>
                  <td>
                    {admission.especialtyCode ? (
                      <Link to={`/especialty/${admission.especialtyCode.id}`}>{admission.especialtyCode.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {admission.appliedCategory ? (
                      <Link to={`/hospital-patient-attribute/${admission.appliedCategory.id}`}>{admission.appliedCategory.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/admission/${admission.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/admission/${admission.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/admission/${admission.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="tinyMedicRestApp.admission.home.notFound">No Admissions found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={admissionList && admissionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Admission;
