import React from 'react';
import { Route } from 'react-router';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HospitalBill from './hospital-bill';
import HospitalBillDetail from './hospital-bill-detail';
import HospitalBillUpdate from './hospital-bill-update';
import HospitalBillDeleteDialog from './hospital-bill-delete-dialog';

const HospitalBillRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HospitalBill />} />
    <Route path="new" element={<HospitalBillUpdate />} />
    <Route path=":id">
      <Route index element={<HospitalBillDetail />} />
      <Route path="edit" element={<HospitalBillUpdate />} />
      <Route path="delete" element={<HospitalBillDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HospitalBillRoutes;
