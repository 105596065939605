export enum ArrivalMode {
  A = 'Ambulance',

  C = 'Car',

  F = 'On foot',

  H = 'Helicopter',

  P = 'Public Transport',

  O = 'Other',

  U = 'Unknown',
}
