import { IAdmission } from 'app/shared/model/admission.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IDiagLab } from 'app/shared/model/diag-lab.model';
import { IDiaglabAssistant } from 'app/shared/model/diaglab-assistant.model';
import { IPrescription } from 'app/shared/model/prescription.model';
import { ILabPrescription } from 'app/shared/model/lab-prescription.model';
import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { IPatientCategory } from 'app/shared/model/patient-category.model';
import { IClinicalDictionary } from 'app/shared/model/clinical-dictionary.model';
import { IHospitalPatientAttribute } from 'app/shared/model/hospital-patient-attribute.model';
import { PatientType } from 'app/shared/model/enumerations/patient-type.model';
import { DiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { AddedByType } from 'app/shared/model/enumerations/added-by-type.model';

export interface IDiaglabBill {
  id?: string;
  billNumber?: string;
  billDate?: number;
  active?: boolean;
  cursor?: number;
  lastupdated?: number;
  microSec?: number;
  timestampentry?: number;
  discount?: number | null;
  cgst?: number | null;
  sgst?: number | null;
  totalCost?: number;
  statusCode?: string | null;
  patientType?: keyof typeof PatientType | null;
  cancelledReason?: string | null;
  discountType?: keyof typeof DiscountType | null;
  discountRemark?: string | null;
  addedByType?: keyof typeof AddedByType | null;
  addedByID?: string | null;
  modifiedByType?: keyof typeof AddedByType | null;
  modifiedByID?: string | null;
  referedByName?: string | null;
  referralComments?: string | null;
  multiplier?: number | null;
  roundOffVal?: number | null;
  printed?: boolean | null;
  printCount?: number | null;
  voided?: boolean | null;
  voidedReason?: string | null;
  voidedDate?: number | null;
  appliedCategoryCode?: string | null;
  admission?: IAdmission | null;
  glucoUser?: IGlucoUser | null;
  diagLab?: IDiagLab | null;
  diaglabAssistant?: IDiaglabAssistant | null;
  prescription?: IPrescription | null;
  labPrescription?: ILabPrescription | null;
  scheme?: IPaymentScheme | null;
  patientCategory?: IPatientCategory | null;
  accessEntry?: IClinicalDictionary | null;
  appliedCategory?: IHospitalPatientAttribute | null;
}

export const defaultValue: Readonly<IDiaglabBill> = {
  active: false,
  printed: false,
  voided: false,
};
